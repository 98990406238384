.section_footer {
  padding-bottom: 68px;
  padding-top: 48px;
  transition: all 0.4s ease;
  z-index: 99;
  position: relative;
  
  .links_f {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: -24px;
    div {
      width: 50%;
      &:nth-child(2n) {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
      }
      a {
        display: block;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 24px;
        line-height: 20px;
        color: #ccc;
        text-decoration: none;
        transition: all 0.3s ease;
        width: fit-content;
        position: relative;
        &::after {
          content: " ";
          width: 0%;
          position: absolute;
          height: 1px;
          background-color: #fff;
          bottom: 0px;
          left: 0px;
          transition: all 0.3s ease;
        }
        &:hover {
          color: #fff !important;
          &::after {
            width: 100%;
          }
        }
        &:hover {
          color: #fff;
        }
      }
    }
  }
  .mobapp {
    div {
      &:nth-child(1) {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
        color: #ccc;
      }
      &:nth-child(2) {
        a {
          margin-right: 16px;
          img {
            &:nth-child(1) {
            }
          }
        }
      }
    }
  }
  .links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    a {
      display: block;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 24px;
      line-height: 20px;
      color: #ccc;
      text-decoration: none;
      transition: all 0.3s ease;
      position: relative;
      &::after {
        content: " ";
        width: 0%;
        position: absolute;
        height: 1px;
        background-color: #fff;
        bottom: 0px;
        left: 0px;
        transition: all 0.3s ease;
      }
      &:hover {
        color: #fff !important;
        &::after {
          width: 100%;
        }
      }
      &:hover {
        color: #fff;
      
      }
      &:last-child {
        margin-bottom: 0px !important;
      }
    }
  }
  .copys {
    padding-top: 30px;
    margin-top: 40px;
    border-top: 1px solid rgba(181, 181, 181, 0.3);
    .copys_links {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      a {
        display: block;
        font-family: 'RobotoCondensed';
        font-size: 14px;
        color: #ffffff7a;
        width: fit-content;
        margin-right: 32px;
        line-height: 14px;
        text-decoration: none;
        position: relative;
        transition: all 0.3s ease;
        &::after {
          content: " ";
          width: 0%;
          position: absolute;
          height: 1px;
          background-color: #ccc;
          bottom: -2px;
          left: 0px;
          transition: all 0.3s ease;
        }
        &:hover {
          color: #ccc !important;
          &::after {
            width: 100%;
          }
        }
        &:hover {
          color: #ccc;
        }
        &:last-child {
          margin-right: 0px !important;
        }
      }
    }
    .copys_links_r {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .copys_links_r_w {
        width: fit-content;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        .langs_wrap {
          transition: all 0.3s ease;
          opacity: 0.6;
          &:hover {
            opacity: 1;
          }
          .langs {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 3px;
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 6px;
            div {
              color: rgba(255, 255, 255, 0.6);
              width: 42px;
              height: 30px;
              cursor: pointer;
              text-align: center;
              transition: all 0.3s ease;
              border-radius: 4px;
              font-size: 20px;
              line-height: 1;
              padding-top: 6px;
              margin-bottom: 0px !important;
              &.active {
                background-color: rgb(26, 26, 26);
                color: #fff;
              }
            }
          }
        }
        .txt {
          font-family: 'RobotoCondensed';
          font-size: 14px;
          color: #ffffff6b;
          text-align: right;
          width: fit-content;
          margin-left: 52px;
          &:first-child {
            margin-left: 0px !important;
            text-align: left;
          }
          &:last-child {
            min-width: fit-content;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .copys_links {
    flex-direction: column !important;
    align-items: flex-start !important;
    a {
      margin-bottom: 15px !important;
    }
  }
  .copys_links_r_w {
    flex-direction: column !important;
    align-items: flex-end !important;
    div {
      margin-bottom: 15px !important;
    }
  }
  .section_footer {
    padding-bottom: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .mobapp {
    margin-top: 20px;
  }
  .links_f {
    margin-top: 36px;
    div {
      width: 100% !important;
      &:nth-child(2n) {
        align-items: flex-start !important;
      }
    }
  }
  .copys_links {
    flex-direction: column !important;
    align-items: flex-start !important;
    a {
      &:nth-child(1) {
        margin-bottom: 8px;
      }
    }
  }
  .copys_links_r {
    flex-direction: column !important;
    align-items: flex-start !important;
    margin-top: 28px;
  }
  .copys_links_r_w {
    flex-direction: column !important;
    align-items: flex-start !important;
    margin-left: 0px !important;
    div {

    margin-left: 0px !important;
    }
  }
}