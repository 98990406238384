.store {
  padding: 120px 0 0;
  // min-height: 100vh;
  // min-height: 100svh;
  transition: all 0.4s ease;
  position: relative;
  z-index: 999;
  // min-height: 100vh;
  // min-height: 100svh;
  .store_wrap {
    width: "100%";
    position: relative;
  }
}

.xflex_hcb {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  .home_card_button {
    width: calc(50% - 14px) !important;
    &:nth-child(2n) {
      
    }
  }
}

@media screen and (min-width: 1300px) {
  .sub_name {
    &.expo {
      margin-top: 11px !important;
      margin-bottom: -10px !important;
    }
  }
}

@media (min-width: 769px) and (max-width: 1300px) {
  .sub_name {
    margin-top: 14px !important;
    margin-bottom: 11px !important;
    &.expo {
      margin-bottom: -10px !important;
    }
    h1 {
      font-size: 32px !important;
      b {
        font-size: 34px !important;
      }
    }
  }
}

.branches {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: 300px;
  overflow-y: scroll;
  .branch_item {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    padding: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
    &.active {
      background-color: red;
      .branch_item__title {
        color: #fff;
      }
      .branch_item__address {
        color: #fff;
      }
    }
    .branch_item__title {
      line-height: 1;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 2px;
      color: #000;
    }
    .branch_item__address {
      font-family: 'RobotoCondensed';
      font-size: 13px;
      font-weight: 400;
      line-height: 1;
      color: rgba(0, 0, 0, 0.6);
    }
  }
}

@media screen and (max-width: 960px) {
  .tg_city {
    .tg_cty {
      flex-direction: column !important;
      .topgun_cty {
        width: 100% !important;
        min-width: 100% !important;
        margin-bottom: 12px !important;
        &:last-child {
          margin-bottom: 0px !important;
        }
      }
    }
  }
}

.tg_city {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  &:last-child {
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
    border-bottom: none !important;
  }
  h5 {
    font-size: 28px;
    font-weight: 700;
  }
  .tg_cty {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    .topgun_cty {
      width: 33.3%;
      min-width: 33.3%;
      margin-bottom: 20px;
      div {
        line-height: 1;
        color: rgba(0, 0, 0, 0.7);
        &:nth-child(1) {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 2px;
        }
        &:nth-child(2) {
          font-size: 14px;
          letter-spacing: 1.2px;
        }
      }
    }
  }
}

@keyframes menu_active_a {
  0% {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9) !important;
  }
  50% {
    width: 100%;
    // left: 10%;
    background-color: rgba(0, 0, 0, 0.3) !important;
  }
  100% {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9) !important;
  }
}

@keyframes menu_active {
  0% {
    width: 100%;
    background-color: rgba(255, 0, 0, 0.9);
  }
  50% {
    width: 80%;
    left: 10%;
    background-color: rgba(255, 0, 0, 0.3);
  }
  100% {
    width: 100%;
    background-color: rgba(255, 0, 0, 0.9);
  }
}

.xstate {
  margin-top: 24px;
  margin-bottom: 0px !important;
  b {
    color: red;
    &.on {
      color: rgb(4, 182, 4);
    }
  }
}

.release {

  p {
    text-align: left !important;
    font-size: 16px !important;
    ol {
      padding-left: 16px !important;
      li {
        margin-bottom: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        &:last-child {
          border-bottom: none;
        }
        &::marker {
          font-weight: bold !important;
        }
        strong {
          color: red;
        }
        span {
          color: rgba(0, 0, 0, 0.4);
          background-color: rgba(0, 0, 0, 0.05);
          border-radius: 3px;
          padding: 4px 5px;
          font-weight: 600;
          font-size: 14px;
          display: inline-block;
        }
      }
    }
  }
}

.xcard {
  // background-color: #fff;
  padding: 16px;
  border-radius: 10px;
  color: #000;
  max-width: 580px;
  margin: 0 auto;
  &.lefted {
    p {
      text-align: left !important;
      font-size: 16px !important;
      ol {
        padding-left: 16px !important;
        li {
          margin-bottom: 8px;
          &::marker {
            font-weight: bold !important;
          }
          b {
            color: red;
          }
        }
      }
    }
  }
  h4 {
    color: #000;
    font-weight: 700;
    font-size: 26px;
  }
  p {
    color: #000 !important;
    a {
      position: relative;
      &::before {
        content: " ";
        position: absolute;
        bottom: -2px;
        width: 100%;
        left: 0%;
        background-color: rgba(255, 0, 0, 0.1);
        height: 1px;
        transition: all 0.5s ease;
        animation: menu_active 3s ease infinite;
      }
      &:hover {
        color: red !important;
        &::before {
          width: 100% !important;
          left: 0% !important;
          // animation: none;
          background-color: red !important;
        }
      }
    }
  }
  .sub_rules {
    margin-top: 6px !important;
    text-align: start !important;
    b {
      color: #000;
    }
  }
  ul {
    margin-top: 10px !important;
  }
}

.wishes_empty {
  color: rgba(255, 255, 255, 0.6) !important;
  margin: 90px 0 80px !important;
}

.add_wish {
  input {
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
    outline: none;
    font-size: 18px;
    padding: 10px 32px;
    width: 100%;
    border-radius: 8px;
    font-family: 'RobotoCondensed';
    color: #fff;
    text-align: center;
    margin-bottom: -20px;
    margin-top: 20px;
    &::placeholder {
      color: rgba(255, 255, 255, 0.6);
    }
  }
}

.wishes_list {
  margin-top: 32px;
  .wl_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 10px 16px;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0px;
    }
    
    .wl_item__text {
      color: #fff;
      font-family: 'RobotoCondensed';
      font-size: 18px;
      width: 100%;
      b {
        display: block;
        font-size: 14px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.4);
      }
    }
    .wl_item__action {
      button {
        border: none;
        background-color: transparent;
        color: #fff;
        outline: none;
      }
    }
  }
}

.what_do_you {
  button {
    border: none;
    outline: none;
    margin-top: -10px;
    margin-bottom: 40px;
    min-width: fit-content;
    padding: 8px 33px 5px 36px;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 2px;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    span {
      // background: #121FCF;
      // background: linear-gradient(to right, #121FCF 0%, #CF1512 100%);
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }
    &:hover {
      // background-color: rgba(255, 255, 255, 0.2);
    }
    &.active {
    }
  }
}

@media screen and (min-width: 960px) {
  .what_do_you {
    position: absolute;
    right: 0px;
    top: 10px;
  }
}

.xsc {
  position: relative;

  .xshop_control_window {
    position: absolute;
    top: 56px;
    width: 400px;
    height: 0px;
    overflow-y: scroll;
    background-color: #fff;
    border-radius: 10px;
    z-index: 999999999;
    box-shadow: 0 10px 40px rgba(0, 0, 0, .4);
    transition: all 0.4s ease;
    &.openned {
      // height: 320px;
    }
    .xscw_body {
      padding: 12px;
    }
  }
}

.xshop_control {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
  .xsc_item {
    width: fit-content;
    min-width: fit-content;
    position: relative;
    transition: all 0.1s ease;
    &:nth-child(2) {
      width: 100%;
      margin: 0 18px;
      button {
        display: none;
      }
    }
    &.heart {
      margin-left: 18px;
      button {
        &.hearted {
          svg {
            path {
              fill: red !important;
            }
          }
          strong {
            position: absolute;
            color: #fff;
            background-color: red;
            border-radius: 100px;
            width: 24px;
            height: 24px;
            display: flex;
            font-size: 14px;
            font-family: 'RobotoCondensed';
            align-items: center;
            justify-content: center;
            letter-spacing: 0.1px;
            top: -6px;
            right: -6px;
          }
        }
      }
    }
    &:nth-child(1) {
      button {
        height: 49px;
        span {
          display: inline-block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          height: 26px;
        }
      }
    }
    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 49px;
      border: none;
      outline: none;
      width: fit-content;
      min-width: fit-content;
      padding: 0 23px 0 26px;
      font-weight: 600;
      font-size: 20px;
      letter-spacing: 2px;
      border-radius: 10px;
      cursor: pointer;
      transition: all 0.3s ease;
      background-color: #fff;
      color: #000;
      span {
        height: 26px;
      }
      b {
        font-weight: 600;
        background: #121FCF;
        background: linear-gradient(to right, #121FCF 20%, #CF1512 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
      }
      span {
        background: #121FCF;
        background: linear-gradient(to right, #121FCF 20%, #CF1512 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      svg {
        margin-right: 4px;
        path {
          fill: #121FCF;
        }
      }
    }
    &.inp {
      position: relative;
      input {
        width: 100%;
        background-color: transparent;
        border: none;
        outline: none;
        color: #fff;
        padding: 9.5px 20px;
        font-size: 20px;
        font-family: 'RobotoCondensed';
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 10px;
      }
      button {
        position: absolute;
        right: 0px;
        top: 0px;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .xshop_control_window {
    width: 100% !important;
    &.relative {
      position: relative !important;
      // height: 54px !important;
      top: -20px !important;
      background-color: rgba(255, 255, 255, 0.2) !important;
      .xscw_body {
        padding: 0px !important;
      }
      .xcats {
        margin-bottom: 0px !important;
      }
      input {
        width: 100%;
        background-color: transparent;
        border: none;
        outline: none;
        color: #fff;
        font-size: 20px;
        font-family: 'RobotoCondensed';
        border-radius: 10px;
        padding: 9.5px 20px;
      }
    }
  }
  .xshop_control {
    .xsc_item {
      svg {
        margin-right: 0px !important;
      }
      &.heart {
        margin-left: 10px;
      }
      &:nth-child(1) {
        button {
          span {
            max-width: 132px;
          }
        }
      }
      &:nth-child(2) {
        margin: 0 10px !important;
        input {
          width: 0px !important;
          background-color: transparent !important;
        }
        button {
          display: flex !important;
          span {
            max-width: 120px !important;
          }
        }
      }
      &:nth-child(3) {
        button {
          svg {
            margin-right: 0px !important;
          }
        }
      }
      button {
        padding: 8px 13px 8px 16px;
        b {
          width: 0px !important;
        }
      }
    }
  }
}

.xcats {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10px;
  max-width: 900px;
  div {
    margin-right: 10px;
    min-width: 100%;
    padding: 8px 13px 5px 16px;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 2px;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0);
    color: #000;
    margin-bottom: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    &:last-child {
      margin-bottom: 0px;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.06);
    }
    &.active {
      background-color: rgba(0, 0, 0, 0.06);
      color: #000;
      span {
        background: #121FCF;
        background: linear-gradient(to right, #121FCF 0%, #CF1512 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .xcats {
    div {
      font-size: 16px;
      padding: 6px 11px 3px 14px;
    }
  }
}

.blocks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  .col {
    display: flex;
    flex-direction: column;
    width: calc(50% - 6px);
    &.fw {
      .b_block {
        margin-bottom: 0px;
      }
    }
  }

  .row {
    width: 100%;
    margin-top: 12px;
    .b_block {
      margin-bottom: 0px !important;
    }
  }

  .b_block {
    height: 86px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    width: 100%;
    // height: -webkit-fill-available;
    padding: 14px 14px 10px;
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    transition: all 0.2s ease;
    transform: scale(1);
    &:hover {
      transform: scale(0.97);
    }
    &:first-child {
      margin-bottom: 12px;
    }
    // backdrop-filter: blur(6px);
    &.stored {
      background-color: rgba(36, 120, 189, 1);
      position: relative;
      padding: 0px;
      .b_b_label {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.1;
        font-family: 'RobotoCondensed';
        padding: 16px 12px;
      }
    }
    &.boned {
      background-color: #fff;
      .b_b_label {
        background: #2478BD;
        background: linear-gradient(to right, #2478BD 0%, #FF3232 50%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        span {
          color: red;
        }
      }
    }
    &.inline {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 14px 14px !important;
      .b_b_icon {
        background-color: #fff;
        width: 42px;
        min-width: 42px;
        height: 42px;
        text-align: center;
        margin-right: 12px;
        border-radius: 100px;
        padding-top: 9px;
        font-size: 18px;
        div {
          color: #000;
        }
      }
      .b_b_label {
        width: 100%;
        margin-bottom: 0px !important;
        div {
          &:nth-child(1) {
            margin-bottom: 2px;
          }
          &:nth-child(2) {
            font-weight: 400;
            color: rgba(255, 255, 255, 0.5);
            font-size: 14px;
          }
        }
      }
      .b_b_go {
        color: #fff;
      }
    }
    .b_b_label {
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.1;
      font-family: 'RobotoCondensed';
      letter-spacing: 0.6px;
      margin-bottom: 6px;
      span {
        position: relative;
        top: -1px;
        left: 4px;
        font-size: 15px;
      }
    }
    .b_b_content {
      .bonuses {
        font-size: 36px;
        font-weight: 600;
        line-height: 1;
        background: #2478BD;
        background: linear-gradient(to right, #2478BD 0%, #FF3232 50%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        svg {
          width: 12px;
          position: relative;
          top: 2px;
          left: -1px;
          path {
            fill: #e73f47;
          }
        }
      }
      .texted {
        color: #fff;
        font-family: 'RobotoCondensed';
        line-height: 1.1;
        font-size: 15px;
        b {
          // background-color: #fff;
          // display: inline-block;
          // border-radius: 4px;
          // line-height: 1;
          // padding: 2px 6px 0px;
          // margin-right: 2px;
          margin-right: 10px;
          span {
            background: #ff0000;
            // background: linear-gradient(to left, #2478BD 0%, #FF3232 70%);
            background-clip: text;
            -webkit-text-fill-color: transparent;
            small {
              svg {
                position: absolute;
                // top: -2px;
                width: 8px;
                margin-top: -1px;
                margin-left: 1px;
                // left: 1px;
                path {
                  fill: #ff0000;
                }
              }
            }
          }
        }
      }
      .go {
        position: absolute;
        left: 12px;
        bottom: 12px;
        span {
          display: block;
          width: 42px;
          height: 42px;
          border-radius: 100px;
          color: rgba(36, 120, 189, 1);
          background-color: #fff;
          font-size: 24px;
          text-align: center;
          padding-top: 6px;
        }
      }
    }
  }
}

.topgun_card {
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(4px);
  border-radius: 12px;
  padding: 14px 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  // background: linear-gradient(to right, #2478BD 0%, #FF3232 100%);
  cursor: pointer;
  transition: all 0.4s ease;
  &:hover {
    backdrop-filter: blur(0.8);
    &::before {
      transform: translate3d(0px, 13px, 0) scale(0.95);
      opacity: var(0.7);
    }
    .ac_arr {
      color: #fff;
    }
  }

  // &::before {
  //   content: "";
  //   z-index: -1;
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   background: linear-gradient(-45deg, #e51414 0%, #007fe6 100% );
  //   transform: translate3d(0px, 10px, 0) scale(0.95);
  //   filter: blur(20px);
  //   opacity: 0.2;
  //   transition: all 0.5s ease;
  //   border-radius: inherit;
  // }

  // &::after {
  //   content: "";
  //   z-index: -1;
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   background: inherit;
  //   border-radius: inherit;
  // }

  &.pced {
    align-items: flex-start !important;
    background-color: transparent !important;
    background: transparent !important;
    padding: 10px 0 2px !important;
    &::before, &::after {
      content: none;
    }
    .ac_arr {
      position: relative;
      top: 5px;
    }
  }
      
  .ac_avatar {
    margin-right: 12px;
    div {
      min-width: 64px;
      width: 64px;
      height: 64px;
      background-color: #f1f1f1;
      border-radius: 100px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .ac_data {
    width: 100%;
    h3 {
      font-size: 28px;
      // font-family: 'RobotoCondensed';
      font-weight: 800;
      letter-spacing: 2px;
      line-height: 1.1;
      color: #fff;
      margin-top: 14px;
      white-space: nowrap;
      text-align: center;
      img {
        width: 82px;
      }
    }
    h4 {
      font-size: 16px;
      font-family: 'RobotoCondensed';
      font-weight: 400;
      letter-spacing: 0.8px;
      line-height: 1.1;
      color: rgba(255, 255, 255, 0.6);
      margin-bottom: 0px;
      margin-top: 0px;
    }
    h5 {
      color: #fff;
      background-color: #ffffff2b;
      border-radius: 4px;
      padding: 4px 12px;
      font-size: 16px;
      margin-top: 8px;
      width: fit-content;
      font-family: 'RobotoCondensed';
    }
  }
  .ac_arr {
    color: rgba(255, 255, 255, 0.5);
    transition: all 0.5s ease;
    position: absolute;
    right: 16px;
    top: 35px;
  }
}

.account_card {
  background-color: rgba(255, 255, 255, 0.07);
  backdrop-filter: blur(4px);
  border-radius: 12px;
  padding: 14px 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  // background: linear-gradient(to right, #2478BD 0%, #FF3232 100%);
  cursor: pointer;
  transition: all 0.4s ease;
  &:hover {
    backdrop-filter: blur(0.8);
    &::before {
      transform: translate3d(0px, 13px, 0) scale(0.95);
      opacity: var(0.7);
    }
    .ac_arr {
      color: #fff;
    }
  }

  &::before {
    // content: "";
    // z-index: -1;
    // position: absolute;
    // top: 0;
    // right: 0;
    // bottom: 0;
    // left: 0;
    // background: linear-gradient(-45deg, #e51414 0%, #007fe6 100% );
    // transform: translate3d(0px, 10px, 0) scale(0.95);
    // filter: blur(20px);
    // opacity: 0.2;
    // transition: all 0.5s ease;
    // border-radius: inherit;
  }

  &::after {
    // content: "";
    // z-index: -1;
    // position: absolute;
    // top: 0;
    // right: 0;
    // bottom: 0;
    // left: 0;
    // background: inherit;
    // border-radius: inherit;
  }

  &.pced {
    align-items: flex-start !important;
    background-color: transparent !important;
    background: transparent !important;
    padding: 10px 0 2px !important;
    &::before, &::after {
      content: none;
    }
    .ac_arr {
      position: relative;
      top: 5px;
    }
  }
      
  .ac_avatar {
    margin-right: 12px;
    div {
      min-width: 64px;
      width: 64px;
      height: 64px;
      background-color: #f1f1f1;
      border-radius: 100px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .ac_data {
    width: 100%;
    h3 {
      font-size: 24px;
      font-family: 'RobotoCondensed';
      font-weight: 800;
      letter-spacing: 0.8px;
      line-height: 1.1;
      color: #fff;
      margin-bottom: 4px;
      white-space: nowrap;
    }
    h4 {
      font-size: 16px;
      font-family: 'RobotoCondensed';
      font-weight: 400;
      letter-spacing: 0.8px;
      line-height: 1.1;
      color: rgba(255, 255, 255, 0.6);
      margin-bottom: 0px;
      margin-top: 0px;
    }
    h5 {
      color: #fff;
      background-color: #ffffff2b;
      border-radius: 4px;
      padding: 4px 12px;
      font-size: 16px;
      margin-top: 8px;
      width: fit-content;
      font-family: 'RobotoCondensed';
    }
  }
  .ac_arr {
    color: rgba(255, 255, 255, 0.5);
    transition: all 0.5s ease;
    
  }
}

@media screen and (max-width: 768px) {
  .account_card {
    align-items: center !important;
    .ac_arr {
      position: relative;
      top: 5px;
    }
  }
  .topgun_card {
    .ac_arr {
      top: 21px !important;
    }
  }
  .sub_card {
    .hc_label {
      font-size: 15px !important;
      span {
        font-size: 12px !important;
        top: -1px !important;
      }
    }
    .sub_name {
      font-size: 34px !important;
      margin-top: 0 !important;
      letter-spacing: 0.2px !important;
      margin-bottom: -5px;
    }
    .sub_change {
      font-size: 13px !important;
      span {
        font-size: 10px !important;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .home_card_button, .ref_card_button {
    &:hover {
      .hcb_data {
        padding-left: 6px;
      }
    }
  }
}

.home_card_button {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  border-radius: 12px;
  padding: 10px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
  transition: all 0.4s ease;
  &:hover {
    background-color: rgba(255, 255, 255, 0.12);
    .hcb_arr {
      color: rgba(255, 255, 255, 0.8);
    }
  }
  .hcb_icon {
    min-width: 46px;
    width: 46px;
    height: 46px;
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    font-size: 20px;
    margin-right: 12px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .hcb_data {
    width: 100%;
    transition: all 0.2s ease;
    div {
      &:nth-child(1) {
        font-size: 18px;
        font-family: 'RobotoCondensed';
        font-weight: 500;
        letter-spacing: 0.8px;
        line-height: 1.1;
        color: #fff;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      &:nth-child(2) {
        font-size: 14px;
        font-family: 'RobotoCondensed';
        font-weight: 300;
        letter-spacing: 0.8px;
        line-height: 1.1;
        margin-top: 4px;
        color: rgba(255, 255, 255, 0.7);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  .hcb_arr {
    color: rgba(255, 255, 255, 0.5);
    transition: all 0.4s ease;
    height: 22px;
  }
}

.link_card {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 10px;
  // margin-bottom: 42px;
  transition: all 0.4s cubic-bezier(0.3, -0.06, 0.43, 0.96);
  .lc_label {
    color: rgba(255, 255, 255, 0.6);
    font-size: 18px;
    letter-spacing: 1px;
    margin-bottom: 0px;
    line-height: 1;
  }
  .lc_text {
    // background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    line-height: 1.1;
    // padding: 8px 16px;
    color: rgba(255, 255, 255, 0.5);
    font-family: 'RobotoCondensed';
    font-size: 14px;
    transition: all 0.4s ease;
    cursor: pointer;
    position: relative;
    span {
      position: absolute;
      right: 0px;
      top: -8px;
      font-size: 24px;
    }
  }
}

.flexed_lcrds {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  .link_card {
    width: 100%;
    cursor: pointer;
    &:first-child {
      margin-right: 12px;
    }
  }
}

.ref_card_button {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  border-radius: 12px;
  padding: 10px 16px;
  margin-bottom: 6px;
  cursor: pointer;
  transition: all 0.4s ease;
  &:hover {
    background-color: rgba(255, 255, 255, 0.12);
    .hcb_arr {
      color: rgba(255, 255, 255, 0.8);
    }
  }
  .rcb_top {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .rcb_bottom {
    .rc_counts {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 0px;
      div {
        color: #fff;
        width: 100%;
        padding: 5px 12px 2px;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 10px;
        text-align: center;
        &:first-child {
          margin-right: 12px;
        }
        span {
          display: block;
          font-family: 'RobotoCondensed';
          font-size: 14px;
          color: rgba(255, 255, 255, 0.6);
          margin-bottom: 4px;
        }
        b {
          font-size: 28px;
          line-height: 1;
          svg {
            width: 11px;
            position: relative;
            top: 2px;
          }
        }
      }
    }
    .rc_line {
      margin-top: 10px;
      margin-bottom: 10px;
      .rc_line__count {
        .rclc__ln {
          position: relative;
          width: 100%;
          height: 20px;
          overflow: hidden;
          text-align: center;
          border-radius: 100px;
          background-color: rgba(255, 255, 255, 0.1);
          div {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            border-radius: 100px;
            background-color: rgba(255, 255, 255, 0.5);
          }
          span {
            color: rgba(255, 255, 255, 0.6);
            font-size: 12px;
            font-weight: 600;
            font-family: 'RobotoCondensed';
            letter-spacing: 0.6px;
            position: relative;
            top: -2px;
          }
        }
        .rclc__cn {
          margin-top: 4px;
          color: rgba(255, 255, 255, 0.5);
          font-family: 'RobotoCondensed';
          font-size: 14px;
          text-align: center;
          b {
            display: inline-block;
            color: rgba(0, 0, 0, 0.8);
            background-color: #fff;
            border-radius: 6px;
            padding: 1px 8px;
            margin-left: 5px;
            // height: 18px;
            line-height: 1;
          }
        }
      }
    }
  }
  .hcb_icon {
    min-width: 46px;
    width: 46px;
    height: 46px;
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    font-size: 20px;
    margin-right: 12px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .hcb_data {
    width: 100%;
    transition: all 0.2s ease;
    div {
      &:nth-child(1) {
        font-size: 18px;
        font-family: 'RobotoCondensed';
        font-weight: 500;
        letter-spacing: 0.8px;
        line-height: 1.1;
        color: #fff;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      &:nth-child(2) {
        font-size: 14px;
        font-family: 'RobotoCondensed';
        font-weight: 300;
        letter-spacing: 0.8px;
        line-height: 1.1;
        margin-top: 4px;
        color: rgba(255, 255, 255, 0.7);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  .hcb_arr {
    color: rgba(255, 255, 255, 0.5);
    transition: all 0.4s ease;
    height: 22px;
  }
}

.to_shop {
  position: absolute;
  width: 100%;
  transition: all 0.8s ease;
  button, a {
    display: block;
    text-decoration: none;
    text-align: center;
    transition: all 0.4s ease;
    width: 100%;
    border: none;
    outline: none;
    border-radius: 100px;
    background-color: #fff;
    color: #000;
    font-weight: 600;
    font-size: 24px;
    line-height: 1;
    padding: 12px 0 9px;
    span {
      background: #121FCF;
      background: linear-gradient(to right, #121FCF 0%, #CF1512 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      padding-top: 6px;
    }
    &:hover {
      background-color: rgb(238, 238, 238);
    }
  }
}

.spgs {
  font-size: 14px !important;
  text-align: left !important;
  margin-top: 1px !important;
  strong {
    font-size: 28px !important;
    position: relative;
    top: 9px;
  }
  
  a {
    position: relative;
    color: #000 !important;
    &::before {
      content: " ";
      position: absolute;
      bottom: -2px;
      width: 100%;
      left: 0%;
      background-color: rgba(0, 0, 0, 0.3) !important;
      height: 1px;
      transition: all 0.5s ease;
      animation: menu_active_a 3s ease infinite !important;
    }
    &:hover {
      color: rgb(0, 0, 0) !important;
      &::before {
        width: 100% !important;
        left: 0% !important;
        // animation: none;
        background-color: rgb(0, 0, 0) !important;
      }
    }
  }
}

.sub_rules {
  margin-top: 34px !important;
}
.sub_name {
  font-size: 55px;
  font-weight: 600;
  color: #fff;
  // font-family: "RobotoCondensed";
  text-transform: unset;
  letter-spacing: 1.6px;
  line-height: 1;
  margin-top: 11px;
  h1 {
    font-size: 35px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 2px;
    b {
      font-weight: 900;
      font-size: 42px;
    }
  }
  h2 {
    font-size: 20px !important;
    margin-bottom: 0px !important;
    span {
      color: rgb(84, 235, 84);
    }
  }
  .icon_arr {
    position: absolute;
    bottom: 13px;
    right: 16px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 18px;
  }
}
.sub_change {
  color: rgba(255, 255, 255, 0.6);
  letter-spacing: 0.8px;
  font-family: 'RobotoCondensed';
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  position: absolute;
  right: 16px;
  bottom: 14px;
  transition: all 0.4s ease;
  &:hover {
    color: rgba(255, 255, 255, 0.8);
    span {
      left: 6px;
    }
  }
  span {
    position: relative;
    left: 3px;
    font-size: 14px;
    transition: all 0.4s ease;
  }
}
.sub_desc {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  font-weight: 400;
  font-family: 'RobotoCondensed';
  margin-bottom: 25px;
  b {
    font-size: 26px;
    font-weight: 600;
    color: #fff;
    display: inline-block;
    margin-top: -8px;
    span {
      position: relative;
      left: 2px;
      svg {
        width: 12px;
      }
    }
  }
}
.sub_price {}
.sub_dates {}

.sub_chx {
  font-family: 'Bebas Neue';
  letter-spacing: 3px;
  text-align: end;
  line-height: 1.3;
  color: #fff;
  font-size: 18px;
  // font-size: 24px;
  font-weight: 600;
  b {
    font-weight: 400;
    font-size: 14px;
    opacity: 0.6;
  }
}


.xul {
  padding-left: 24px;
  list-style: none;
  margin-top: 40px;
  li {
    list-style: none;
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: 0.6px;
    margin-bottom: 20px;
    font-family: 'RobotoCondensed';
    position: relative;
    transition: all 0.4s ease;
    &::before {
      content: url("data:image/svg+xml,%3Csvg width='16' height='15' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.00158 0.683594L10.2637 5.28788L15.319 6.02128L11.6615 9.60491L12.526 14.6609L8.00158 12.2755L3.4777 14.6609L4.34031 9.60491L0.680664 6.02128L5.73758 5.28788L8.00158 0.683594Z' stroke='%23EB3223'/%3E%3C/svg%3E");
      margin-right: 10px;
      position: absolute;
      top: 2px;
      left: -25px;
    }
    span {
      color: rgb(27, 181, 27);
      font-weight: 600;
    }
  }
}

@media screen and (max-width: 960px) {
  .sub_chx {
    letter-spacing: 1.6px !important;
    line-height: 1.3 !important;
  }
}

.whitemodal_centered {
  // padding-bottom: 42px;
  h3 {
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 42px;
    font-weight: 400;
    margin-bottom: 4px;
    span {
      color: red;
    }
  }
  .sub_rules {
    margin-top: 64px;
    font-weight: 300;
    font-size: 18px !important;
    span {
      font-weight: 300;
      font-size: 22px !important;
    }
    big {
      font-size: 28px !important;
    }
  }
  .link {
    transition: all 0.3s ease;
    width: fit-content;
    margin: -8px auto 16px;
    &:hover {
      svg {
        color: rgb(0, 0, 0) !important;
      }
    }
    svg {
      margin-right: 4px;
      transition: all 0.3s ease;
      color: rgba(0, 0, 0, 0.6);
      width: 14px;
    }
    a {
      color: rgba(0, 0, 0, 0.6);
      text-decoration: none;
      position: relative;
      transition: all 0.3s ease;
      font-family: 'RobotoCondensed';
      font-size: 18px;
      &::after {
        content: " ";
        width: 0%;
        position: absolute;
        height: 1px;
        background-color: rgb(0, 0, 0);
        bottom: 0px;
        left: 0px;
        transition: all 0.3s ease;
      }
      &:hover {
        color: rgb(0, 0, 0) !important;
        &::after {
          width: 100%;
        }
      }
    }
  }
}

.head_anim {
  transition: all 0.3s ease;
  padding-top: 16px;
  width: calc(100% + 30px);
  left: -15px;
  position: absolute;
  background-color: #fff;
}

@media screen and (min-width: 1280px) {
  .head_anim {
    left: 0px !important;
    width: 100% !important;
  }
}

@media screen and (max-width: 768px) {
  .head_anim {
    &.activated {
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100%;
      background-color: #fff;
      z-index: 99999999;
      box-shadow: 0 5px 12px rgba(0, 0, 0, 0.12);
    }
  }
  .link {

    &.sfx {
      margin-top: -41px;
      opacity: 0;
    }
  }
}

.blurmodal_centered {
  padding-bottom: 42px;
  h3 {
    color: #fff;
    text-align: center;
    font-size: 42px;
    font-weight: 400;
    span {
      color: red;
    }
  }
  .sub_rules {
    margin-top: 64px;
    font-weight: 300;
    font-size: 18px !important;
    span {
      font-weight: 300;
      font-size: 22px !important;
    }
    big {
      font-size: 28px !important;
    }
  }
  .link {
    transition: all 0.3s ease;
    width: fit-content;
    margin: -8px auto 16px;
    &:hover {
      svg {
        color: #fff !important;
      }
    }
    svg {
      margin-right: 4px;
      transition: all 0.3s ease;
      color: rgba(255, 255, 255, 0.6);
      width: 14px;
    }
    a {
      color: rgba(255, 255, 255, 0.6);
      text-decoration: none;
      position: relative;
      transition: all 0.3s ease;
      font-family: 'RobotoCondensed';
      font-size: 18px;
      &::after {
        content: " ";
        width: 0%;
        position: absolute;
        height: 1px;
        background-color: #fff;
        bottom: 0px;
        left: 0px;
        transition: all 0.3s ease;
      }
      &:hover {
        color: #fff !important;
        &::after {
          width: 100%;
        }
      }
    }
  }
  p {
    color: #fff;
    text-align: center;
    font-size: 18px;
    letter-spacing: 0.8px;
    font-family: 'RobotoCondensed';
    line-height: 1.2 !important;
    strong {
      color: red !important;
    }
    a {
      color: rgba(255, 0, 0, 0.9);
      text-decoration: none;
      position: relative;
      transition: all 0.3s ease;
      font-family: 'RobotoCondensed';
      // font-size: 18px;
      // &::after {
      //   content: " ";
      //   width: 0%;
      //   position: absolute;
      //   height: 1px;
      //   background-color: red;
      //   bottom: 0px;
      //   left: 0px;
      //   transition: all 0.3s ease;
      // }
      &:hover {
        color: red !important;
        &::before {
          width: 100%;
        }
      }
    }
  }
  .act {
    width: 100%;
    text-align: center;
    margin-top: 38px;
    button {
      border: none;
      outline: none;
      background-color: red;
      color: #fff;
      padding: 10px 42px;
      border-radius: 10px;
      letter-spacing: 0.8px;
      font-size: 18px;
      font-weight: 500;
      font-family: 'RobotoCondensed';
    }
  }
  ul {
    padding-left: 24px;
    list-style: none;
    max-width: 500px;
    margin: 32px auto 0;
    li {
      list-style: none;
      font-size: 16px;
      line-height: 1.2;
      letter-spacing: 0.6px;
      margin-bottom: 16px;
      font-family: 'RobotoCondensed';
      position: relative;
      color: rgba(0, 0, 0, 0.9);
      transition: all 0.4s ease;
      
      &::before {
        content: url("data:image/svg+xml,%3Csvg width=%2716%27 height=%2715%27 viewBox=%270 0 16 15%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath fill-rule=%27evenodd%27 clip-rule=%27evenodd%27 d=%27M8.00158 0.683594L10.2637 5.28788L15.319 6.02128L11.6615 9.60491L12.526 14.6609L8.00158 12.2755L3.4777 14.6609L4.34031 9.60491L0.680664 6.02128L5.73758 5.28788L8.00158 0.683594Z%27 fill=%27%23EB3223%27/%3E%3C/svg%3E");
        margin-right: 10px;
        position: absolute;
        top: 2px;
        left: -25px;
      }
      b {
        color: rgb(0, 0, 0);
      }
      span {
        color: #1bb51b;
        font-size: 18px;
        font-weight: 600;
      }
      strong {
        color: red;
        &.big {
          // font-size: 26px;
        }
      }
    }
  }
}

.objectively {
  padding-bottom: 42px;
  h4 {
    font-family: 'RobotoCondensed';
    font-weight: 600;
    font-size: 20px;
  }
  .xma {
    display: block;
    border: none;
    outline: none;
    background-color: red;
    color: #fff;
    padding: 10px 42px;
    border-radius: 10px;
    letter-spacing: 0.8px;
    font-size: 18px;
    width: fit-content;
    font-weight: 500;
    font-family: "RobotoCondensed";
    &:hover {
      color: #fff !important;
      background-color: rgb(222, 3, 3) !important;
    }
  }
  h3 {
    color: #fff;
    text-align: center;
    font-size: 42px;
    font-weight: 400;
    span {
      color: red;
    }
  }
  .sub_rules {
    margin-top: 64px;
    font-weight: 300;
    font-size: 18px !important;
    span {
      font-weight: 300;
      font-size: 22px !important;
    }
    big {
      font-size: 28px !important;
    }
  }
  .link {
    transition: all 0.3s ease;
    width: fit-content;
    margin: -8px auto 16px;
    &:hover {
      svg {
        color: #fff !important;
      }
    }
    svg {
      margin-right: 4px;
      transition: all 0.3s ease;
      color: rgba(255, 255, 255, 0.6);
      width: 14px;
    }
    a {
      color: rgba(255, 255, 255, 0.6);
      text-decoration: none;
      position: relative;
      transition: all 0.3s ease;
      font-family: 'RobotoCondensed';
      font-size: 18px;
      &::after {
        content: " ";
        width: 0%;
        position: absolute;
        height: 1px;
        background-color: #fff;
        bottom: 0px;
        left: 0px;
        transition: all 0.3s ease;
      }
      &:hover {
        color: #fff !important;
        &::after {
          width: 100%;
        }
      }
    }
  }
  p {
    color: #000;
    // text-align: center;
    font-size: 18px;
    letter-spacing: 0.8px;
    font-family: 'RobotoCondensed';
    line-height: 1.2 !important;
    strong {
      color: red !important;
    }
    a {
      color: rgba(255, 0, 0, 0.9);
      text-decoration: none;
      position: relative;
      transition: all 0.3s ease;
      font-family: 'RobotoCondensed';
      // font-size: 18px;
      // &::after {
      //   content: " ";
      //   width: 0%;
      //   position: absolute;
      //   height: 1px;
      //   background-color: red;
      //   bottom: 0px;
      //   left: 0px;
      //   transition: all 0.3s ease;
      // }
      &:hover {
        color: red !important;
        &::before {
          width: 100%;
        }
      }
    }
  }
  .act {
    width: 100%;
    text-align: center;
    margin-top: 38px;
    button {
      border: none;
      outline: none;
      background-color: red;
      color: #fff;
      padding: 10px 42px;
      border-radius: 10px;
      letter-spacing: 0.8px;
      font-size: 18px;
      font-weight: 500;
      font-family: 'RobotoCondensed';
    }
  }
  ul {
    padding-left: 24px;
    list-style: none;
    // max-width: 500px;
    margin: 22px auto 0;
    li {
      list-style: none;
      font-size: 16px;
      line-height: 1.2;
      letter-spacing: 0.6px;
      margin-bottom: 16px;
      font-family: 'RobotoCondensed';
      position: relative;
      color: rgba(0, 0, 0, 0.9);
      transition: all 0.4s ease;
      
      &::before {
        content: url("data:image/svg+xml,%3Csvg width=%2716%27 height=%2715%27 viewBox=%270 0 16 15%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath fill-rule=%27evenodd%27 clip-rule=%27evenodd%27 d=%27M8.00158 0.683594L10.2637 5.28788L15.319 6.02128L11.6615 9.60491L12.526 14.6609L8.00158 12.2755L3.4777 14.6609L4.34031 9.60491L0.680664 6.02128L5.73758 5.28788L8.00158 0.683594Z%27 fill=%27%23EB3223%27/%3E%3C/svg%3E");
        margin-right: 10px;
        position: absolute;
        top: 2px;
        left: -25px;
      }
      b {
        color: rgb(0, 0, 0);
      }
      span {
        color: #1bb51b;
        font-size: 18px;
        font-weight: 600;
      }
      strong {
        color: red;
        &.big {
          // font-size: 26px;
        }
      }
    }
  }
}

.refs {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: hidden;
  width: 100%;
  margin-top: 8px;
  margin-bottom: -3px;
  .refs_item {
    margin-right: 7px;
    color: rgba(255, 255, 255, 0.6);
    border: 2px solid rgba(255, 255, 255, 0.6);
    border-radius: 200px;
    width: 48px;
    min-width: 48px;
    height: 48px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease;
    cursor: pointer;
    overflow: hidden;
    img {
      width: 100%;
    }
    div {
      width: 42px;
      min-width: 42px;
      height: 42px;
      border-radius: 100px;
      background-color: #f1f1f1;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
.refs_desc {
  margin-bottom: 0px;
  margin-top: 8px;
  font-family: 'RobotoCondensed';
  color: rgba(255, 255, 255, 0.4) !important;
  font-size: 16px;
  font-weight: 400;
}

.demo_productsx {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  // justify-content: space-between;
}

.demo_products {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  transition: all 0.4s ease;
  // justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .demo_products, 
  .demo_productsx {
    justify-content: space-between;
  }
}

.stories {
  margin-bottom: 12px;
  width: calc(100% + 24px);
  position: relative;
  left: -12px;
  .stories_wrap {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    padding-left: 12px;
    &::-webkit-scrollbar {
      width: 1px;
      max-height: 0px;
    }
  }

  &.pced {
    width: unset !important;
    left: unset !important;
    .story_item {
      margin-right: 0px;
    }
  }

  .story_item {
    border-radius: 15px;
    padding: 3px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    margin-right: 8px;
    transition: all 0.4s ease;
    transform: scale(1);
    cursor: pointer;
    &:hover {
      border-color: rgba(255, 255, 255, 0.6);
      transform: scale(0.95);
    }
    div {
      width: 100%;
      min-width: 100%;
      height: 67px;
      border-radius: 10px;
      background-color: #000;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      b {
        display: flex;
        width: 100%;
        height: 100%;
        text-align: center;
        color: #fff;
        font-size: 25px;
        background-color: #000000a8;
        border-radius: 10px;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .story_item {
    div {
      height: 65px !important;
    }
  }
}

.all_products {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 42px;
}

.home_card {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  border-radius: 12px;
  padding: 14px 16px;
  margin-bottom: 16px;
  position: relative;
  z-index: 9999999;
  cursor: pointer;
  &.sub_card {
    background: linear-gradient(to right, #2478BD 0%, #FF3232 100%);
    &::before {
      content: "";
      z-index: -1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(-45deg, #e51414 0%, #007fe6 100% );
      transform: translate3d(0px, 10px, 0) scale(0.95);
      filter: blur(20px);
      opacity: 0.5;
      transition: all 0.5s ease;
      border-radius: inherit;
    }
  }
  &:hover {
    .hc_label {
      span {
        left: 6px;
      }
    }
  }
  .hc_label {
    color: rgba(255, 255, 255, 0.6);
    font-size: 18px;
    letter-spacing: 1.4px;
    cursor: pointer;
    position: relative;
    span {
      font-size: 14px;
      position: relative;
      top: -1.7px;
      left: 0px;
      transition: all 0.4s ease;
    }
    b {
      position: absolute;
      left: 80px;
      top: -1px;
      background-color: #ffffff2e;
      border-radius: 31px;
      line-height: 1;
      padding: 4px 10px 1px;
    }
  }
}

.store_body {
  height: 100%;
  position: relative;
  // padding-right: 35px;
  // &.afterVisible {
  //   &::after {
  //     top: 85px !important;
  //     opacity: 1 !important;
  //   }
  // }
  // &::after {
  //   content: " ";
  //   width: 1px;
  //   height: 100%;
  //   position: absolute;
  //   top: 70px;
  //   left: -35px;
  //   opacity: 0;
  //   transition: all 0.4s ease;
  //   background-color: rgba(255, 255, 255, 0.1);
  // }
  // &::after {
  //   left: unset;
  //   right: 6px;
  // }
  .store_body__ttl {
    color: rgba(255, 255, 255, 0.5);
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 2px;
    margin-bottom: 46px;
    position: relative;
    text-align: right;
    top: -8px;
    transition: all 0.4s ease;
    z-index: 99999999999;
    cursor: pointer;
    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
}



.page_blink {
  background: radial-gradient(ellipse at center, rgba(255, 50, 50, 0.7) 0%, transparent 55%);
  width: 1688px;
  height: 1688px;
  position: absolute;
  left: -944px;
  margin: auto;
  transition: all 1s ease;
  animation: rotatta 12s infinite linear;
  opacity: 0;

  &.blue {
    background: radial-gradient(ellipse at center, rgba(36, 120, 189, 0.7) 0%, transparent 55%);
    animation: rotatta2 12s infinite linear;
  }
  &.right {
    left: unset !important;
    right: -944px !important;
    transition: all 1.5s ease;
  }
  &.toped {
    margin-top: -600px;
  }
  &.toped2 {
    margin-top: -800px;
  }
  &.toped22 {
    margin-top: -400px;
  }
  &.toped4 {
    margin-top: -1200px;
  }
  &.toped5 {
    margin-top: -1600px;
  }
  &.toped3 {
    margin-top: -50px;
  }
}

@keyframes rotatta {
  0% { 
    transform: rotate(0deg) translateY(100px) rotate(0deg); 
  } 
  100% { 
    transform: rotate(360deg) translateY(100px) rotate(-360deg); 
  } 
}

@keyframes rotatta2 {
  0% { 
    transform: rotate(0deg) translateY(100px) rotate(0deg); 
  } 
  100% { 
    transform: rotate(-360deg) translateY(100px) rotate(360deg); 
  } 
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.page_blink {
  //background: radial-gradient(ellipse at center, rgba(36, 120, 189, 0.7) 0%, transparent 55%) !important;
  background: radial-gradient(ellipse at center, rgba(255, 50, 50, 0.7) 0%, transparent 55%) !important;
  &.blue {
    //background: radial-gradient(ellipse at center, rgba(255, 50, 50, 0.7) 0%, transparent 55%) !important;
    top: 260px;
  }
  &.reddz {
   // background: radial-gradient(ellipse at center, rgba(255, 50, 50, 0.7) 0%, transparent 55%) !important;
    background: radial-gradient(ellipse at center, rgba(36, 120, 189, 0.7) 0%, transparent 55%) !important;
  }
  &.toped {
    margin-top: -550px !important;
  }
}

@media screen and (max-width: 768px) {
  .story_item {
    min-width: 75px;
  }
  .home_card_button {
    margin-bottom: 12px !important;
  }
  .soon {
    margin-bottom: 12px !important;
  }
  .account_card {
    background-color: transparent !important;
    background: transparent !important;
    padding: 10px 0 2px !important;
    &::before, &::after {
      content: none;
    }
  }
}


.lang {
  position: absolute;
  top: -32px;
  transition: all 0.4s ease;
  z-index: 9999999999;
}

.history_link {
  cursor: pointer;
  position: relative;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
  font-family: "RobotoCondensed";
  margin-bottom: 22px;
  margin-top: -4px;
  transition: all 0.4s ease;
  &:hover {
    color: rgba(255, 255, 255, 0.9);
    span {
      left: 10px;
    }
  }
  span {
    font-size: 12px;
    position: relative;
    // top: -1px;
    left: 4px;
    transition: all 0.4s ease;
  }
}

@media screen and (max-width: 768px) {
  .lang {
    display: none;
  }
  .sub_rules {
    b {
      line-height: 1.2;
    }
  }

  .mobx1 {
    margin-top: 0px !important;
  }
}

.features {
  padding-top: 10px;
  padding-bottom: 0px;
  margin-top: 40px;
  margin-bottom: 70px;
  position: relative;
  z-index: 99;
  .ft {
    // border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  h2 {
    text-align: left;
    font-size: 56px;
    font-weight: 700;
    margin-bottom: 56px;
    color: #fff;
    position: relative;
    transition: all 0.4s ease;
  }
  .features_list {
    display: flex;
    flex-direction: row;
    align-items: center;
    .features_item {
      text-align: center;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 300px;
      .features_item__icon {
        font-size: 50px;
        color: #fff;
        svg {

        }
      }
      .features_item__ttl {
        color: #fff;
        font-size: 42px;
        font-weight: 600;
        margin-bottom: 20px;
        span {
          margin-right: 14px;
        }
      }
      .features_item__text {
        max-width: 90%;
        margin: 0 auto;
        color: #fff;
        font-size: 39px;
        // font-family: 'RobotoCondensed';
        line-height: 1.1;
        letter-spacing: 1.5px;
        height: 200px;
        display: flex;
        align-items: center;
        flex-direction: row;
        p {
          display: block;
          margin-bottom: 0;
        }
        span {
          font-family: 'Bebas Neue';
          // font-size: 56px;
          font-weight: 600;
        }
      }
    }
  }
}

.acted {
  width: 100%;
  text-align: center;
  margin-top: 46px;
  margin-bottom: 0px;
  position: relative;
  z-index: 999999999;
  transition: all 0.8s ease;
  
  button, a {
    display: inline-block;
    text-decoration: none;
    background: transparent;
    border: none;
    outline: none;
    padding: 1px;
    margin-right: 16px;
    border-radius: 12px;
    position: relative;
    height: 50px;
    width: 240px;
    &:nth-child(1) {
      span {
        padding: 10px 46px !important;
      }
    }
    span {
      background: rgba(188, 188, 188, 0.25);
      padding: 10px 76px;
      font-size: 20px;
      display: block;
      font-family: 'RobotoCondensed';
      border-radius: 12px;
      font-weight: 400;
      height: 50px;
      width: 240px;
      color: #fff;
      transition: all 0.3s ease;
      z-index: 999;
      position: relative;
      border: 1px solid #CDCDCD;
      border-style: solid;
      backdrop-filter: blur(4px);
    }
    b {
      position: absolute;
      z-index: 9999999;
      color: #fff;
      font-family: 'RobotoCondensed';
      border-radius: 12px;
      font-weight: 400;
      font-size: 20px;
      top: 11px;
      left: 0px;
      width: 240px;
      text-align: center;
    }
    &::before {
      position: absolute;
      content: " ";
      border-radius: 12px;
      top: 0;
      left: 0;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      z-index: 99;
      opacity: 0;
      z-index: 99999;
      transition: all 0.3s ease;
      background: linear-gradient(262deg, #1C5D93 27.77%, #DB0609 95.47%);
    }
    &:hover {
      &::before {
        opacity: 1;
      }
      span {
        //border: 1px solid transparent;
        //background: transparent;
        border: 1px solid transparent;
      }
    }
  }
}

.store_body__flex {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  &.unsub {
    // filter: blur(5px);
    user-select: none;
    pointer-events: none;
    opacity: 0.5;
  }
  &.sbfx {
    border-bottom: none !important;
    margin-bottom: 0px !important;
  }
  .sbg_item {
    width: calc(25% - 20px) !important;
    min-width: calc(25% - 20px);
    margin-right: 20px;

    &:nth-child(2n) {
      &:hover {
        .sbg_item_w {
          .sale {
            span {
              color: red !important;
            }
          }
        }
      }
    }
    &:nth-child(4n) {
      width: 25% !important;
      min-width: 25% !important;
      margin-right: 0px;
    }
  }
}

.store_body__grid, .store_body__flex {
  // border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  padding-bottom: 64px;
  margin-bottom: 64px;
  .sbg_item {
    width: 100%;
    // padding-right: 14px;
    height: 100%;
    display: block;
    text-decoration: none;
    // &:nth-child(2n) {
    //   padding-left: 6px;
    //   padding-right: 0px !important;
    // }
    // &:nth-child(3n) {
    //   padding-left: 6px;
    //   padding-right: 0px !important;
    // }
    &:nth-child(2n) {
      .sbg_item_w {
        &:hover {
          .sale {
            span {
              color: red !important;
            }
          }
        }
      }
    }
    .sbg_item_w {
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 12px;
      padding: 16px;
      cursor: pointer;
      transition: all 0.4s ease;
      position: relative;
      overflow: hidden;
      &:hover {
        transform: scale(0.98);
        .sbg_item__img {
          img {
            // transform: scale(1.06);
          }
        }
        .sale {
          background-color: rgba(255, 255, 255, 0.9);
          span {
            color: rgb(8, 50, 220);
          }
          &.red {
            span {
              color: red !important;
            }
          }
        }
        .count {
          background-color: rgba(255, 255, 255, 0.18);
          span {
            color: rgba(255, 255, 255, 0.8);
          }
        }
        .sbg_item__data {
          h2 {
            color: #fff;
          }
          p {
            color: rgba(255, 255, 255, 0.8);
            small {
              color: rgba(255, 255, 255, 0.8);
              svg {
                path {
                  fill: rgba(255, 255, 255, 0.8);
                }
              }
            }
          }
        }
      }
      .count {
        background-color: rgba(255, 255, 255, 0.13);
        width: 60px;
        height: 35px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        left: 0px;
        top: 0px;
        border-bottom-right-radius: 12px;
        transition: all 0.4s ease;
        span {
          color: rgba(255, 255, 255, 0.6);
          font-size: 23px;
          font-weight: 600;
          position: relative;
          top: 2px;
          text-align: center;
          width: 100%;
          display: block;
          transition: all 0.4s ease;
          small {
            font-size: 14px;
          }
        }
      }
      .sale {
        background-color: #ffffff21;
        width: 60px;
        height: 35px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        right: 0px;
        top: 0px;
        border-bottom-left-radius: 12px;
        transition: all 0.4s ease;
        span {
          color: rgba(255, 255, 255, 0.6);
          font-size: 23px;
          font-weight: 600;
          position: relative;
          top: 2px;
          text-align: center;
          width: 100%;
          display: block;
          transition: all 0.4s ease;
        }
      }
      .sbg_item__img {
        text-align: center;
        padding: 30px 0 40px;
        height: 239px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 60%;
          transition: all 0.4s ease;
        }
      }
      .sbg_item__data {
        transition: all 0.4s ease;
        h2 {
          letter-spacing: 0.9px;
          color: rgba(255, 255, 255, 0.7);
          text-align: center;
          font-size: 28px;
          transition: all 0.4s ease;
          margin-bottom: 0px;
          font-weight: 400;
          b {
            font-weight: 600;
          }
          span {
            font-weight: 500;
          }
        }
        p {
          // display: none;
          font-family: "RobotoCondensed";
          font-size: 18px;
          color: rgba(255, 255, 255, 0.4);
          text-align: center;
          letter-spacing: 0.6px;
          line-height: 1;
          max-lines: 1;
          margin-bottom: 0;
          transition: all 0.4s ease;
          small {
            font-size: 12px;
            color: rgba(255, 255, 255, 0.4);
            margin-left: 3px;
            svg {
              width: 10px;
              position: relative;
              top: -3px;
              path {
                transition: all 0.4s ease;
                fill: rgba(255, 255, 255, 0.4);
              }
            }
          }
        }
        div {
          display: none;
          span {
            &:nth-child(1) {}
            &:nth-child(2) {}
            b {
  
            }
            small {
  
            }
          }
        }
        button {
          display: none;
          background-color: #000;
          color: #fff;
          border-radius: 8px;
          border: none;
          outline: none;
          font-weight: 300;
          padding: 8px 16px 6px;
          letter-spacing: 1.8px;
        }
      }
    }
  }
}

.store_banner {
  margin-bottom: 24px;
  padding-bottom: 22px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  .store_banner__nav {
    position: absolute;
    top: 35%;
    left: 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: all 0.4s ease;
    div {
      width: fit-content;
      color: #fff;
      font-size: 24px;
      position: relative;
      opacity: 0.6;
      line-height: 1;
      cursor: pointer;
      transition: all 0.4s ease;
      &:hover {
        opacity: 0.9;
        transform: scale(1.1);
      }
      &:nth-child(1) {
        left: -35px;
      }
      &:nth-child(2) {
        right: -35px;
        text-align: right;
      }
      svg {

      }
    }
  }
  .store_banner__slides {
    // background-color: rgba(255, 255, 255, 0.1);
    // backdrop-filter: blur(6px);
    border-radius: 12px;
    padding: 20px 0;
    position: relative;
    z-index: 99;
    height: 394px;
    .slide {
      position: absolute;
      width: 100%;
      .slide_img {
        position: absolute;
        width: 540px;
        height: 286px;
        text-align: end;
        top: 40px;
        right: 70px;
        transition: all 0.4s ease;
        img {
          max-height: 100%;
        }
      }
      h1 {
        font-size: 72px;
        font-weight: 600;
        color: #fff;
        transition: all 0.4s ease;
        position: relative;
      }
      p {
        max-width: 500px;
        color: #fff;
        font-family: 'RobotoCondensed';
        margin-bottom: 38px;
        transition: all 0.4s ease;
        height: 97px;
        overflow: hidden;
      }
      .soon {
        margin-bottom: 34px;
        padding: 6px 26px;
        font-size: 20px;
        color: #000;
        text-transform: uppercase;
        width: fit-content;
        font-family: 'RobotoCondensed';
        transition: all 0.8s ease;
        opacity: 0;
        position: relative;
        --bg-width: 0%;
        &::before {
          content: " ";
          position: absolute;
          top: 0;
          left: 0;
          width: var(--bg-width);
          height: 100%;
          background-color: #fff;
          z-index: -1;
          transition: all 1s ease;
          max-width: 100%;
        }
        strong {
          font-size: 12px !important;
        }
      }
    }
  }
}

.hp_btn {
  transition: all 0.7s ease;
  a {
    display: inline-block;
  }
  button,a {
    background: transparent;
    border: none;
    outline: none;
    padding: 1px;
    margin-right: 16px;
    border-radius: 12px;
    position: relative;
    height: 51px;
    width: 241px;
    overflow: hidden;
    --psop: 0;
    --pslft: 180px;
    &:nth-child(1) {
      span {
        padding: 10px 46px !important;
      }
    }
    span {
      background: rgba(188, 188, 188, 0.25);
      padding: 10px 76px;
      font-size: 20px;
      display: block;
      font-family: 'RobotoCondensed';
      border-radius: 12px;
      font-weight: 400;
      height: 50px;
      width: 240px;
      color: #fff;
      transition: all 0.3s ease;
      z-index: 999;
      position: relative;
      border: 1px solid #CDCDCD;
      border-style: solid;
      backdrop-filter: blur(4px);
    }
    b {
      position: absolute;
      z-index: 9999999;
      color: #fff;
      font-family: 'RobotoCondensed';
      border-radius: 12px;
      font-weight: 400;
      font-size: 20px;
      top: 11px;
      left: 0px;
      width: 240px;
      text-align: center;
    }
    &:nth-child(1) {
      &::after {
        position: absolute;
        content: " ";
        border-radius: 500px;
        background: radial-gradient(50% 50% at 50% 50%, #2477BD 0%, rgba(0, 0, 0, 0.00) 100%);
        width: 423px;
        height: 423px;
        top: -50px;
        left: var(--pslft);
        z-index: 9999;
        transition: all 6s ease;
      }
    }
    &::before {
      position: absolute;
      content: " ";
      border-radius: 12px;
      top: 0;
      left: 0;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      z-index: 99;
      opacity: 0;
      z-index: 99999;
      transition: all 0.3s ease;
      background: linear-gradient(262deg, #1C5D93 27.77%, #DB0609 95.47%);
    }
    &:hover {
      &::before {
        opacity: 1;
      }
      span {
        //border: 1px solid transparent;
        //background: transparent;
        border: 1px solid transparent;
      }
    }
  }
}

.mat_sl_pag {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    button {
      background-color: transparent;
      border: none;
      outline: none;
      font-size: 20px;
      color: #fff;
      padding: 0 16px;
      cursor: pointer;
      opacity: 0.6;
      transition: all 0.4s ease;
      &:hover {
        opacity: 1;
      }
    }
  }
}




.star_card {
  margin-bottom: 0px;
  transition: all 0.4s ease;
  position: relative;

  &.scnd {
    .sc_star {
      //margin-bottom: 30px;
    }
  }
  .sc_star {
    margin-bottom: 40px;
    svg {
      transition: fill 0.4s ease;
      width: 45px;
      height: 45px;
      display: block;
      path {
        fill: red;
        transition: fill 0.4s ease;
      }
    }
    &.blue {
      svg {
        path {
          fill: rgb(8, 50, 220);
          stroke: rgb(8, 50, 220);
        }
      }
    }
    &.white {
      svg {
        path {
          fill: rgb(255, 255, 255);
          stroke: rgb(255, 255, 255);
        }
      }
    }
    span {
      background-image: url('../images/star_fill.svg');
      transition: all 0.2s ease;
      width: 45px;
      height: 45px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
    }
  }
  .sc_ikey {
    margin-bottom: 35px;
    position: relative;
    transition: all 0.1s ease;
    span {
      background-image: url('../images/ikey.svg');
      transition: all 0.4s ease;
      width: 96px;
      height: 88px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
    }
  }
  .sc_text {
    color: #FFFFFF;
    font-family: 'RobotoCondensed';
    font-size: 20px;
    font-weight: 400;
    line-height: 1.1;
    position: relative;
    transition: all 0.1s ease;

    .topped {
      position: relative;
      top: -10px;
      left: 4px;
    }
    .stared {
      font-size: 50px;
      position: relative;
      top: -12px;
    }
    .smalled {
      font-size: 22px !important;
      font-weight: 400 !important;
    }
    b {
      font-weight: 700;
      color: #fff;
    }
    span {
      color: #fff;
      &.big {
        font-family: 'Bebas Neue';
        font-size: 40px;
        line-height: 58px;
        font-weight: 700;
        margin-top: 12px;
        b {
          font-family: 'Bebas Neue';
          font-size: 25px;
          font-weight: 300;
        }
      }
    }
  }
}


.mtx1 {
  font-family: 'RobotoCondensed';
  font-size: 25px;
  color: #1F1F1F;
  font-weight: 400;
  margin-bottom: 0px;

  line-height: 1;
  margin-top: 0px !important;
  b {
    font-weight: 700;
  }
  br {
    display: none;
  }
}


.storelist {
  position: relative;
  z-index: 999;
  &.sl_m {
    margin-top: 32px;
    h2 {
      margin-bottom: 20px;
    }
  }
  h2 {
    transition: all 0.1s ease;
    text-align: left;
    font-size: 56px;
    font-weight: 700;
    margin-bottom: 6px;
    color: #fff;
    position: relative;
  }
  h3 {
    transition: all 0.1s ease;
    font-family: 'RobotoCondensed';
    font-size: 20px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 42px;
    letter-spacing: 0.6px;
    b {
      font-weight: 600;
    }
    span {
      font-weight: 600;
      color: red;
      a {
        color: red;
        text-decoration: none;
        position: relative;
        &::after {
          content: " ";
          width: 0%;
          position: absolute;
          height: 1px;
          background-color: red;
          bottom: 0px;
          left: 0px;
          transition: all 0.3s ease;
        }
        &:hover {
          color: red !important;
          &::after {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .mat_sl_pag {
    top: -151px;
    z-index: 99999999;
    div {
      width: 100%;
      button {
        padding-top: 1px;
        transition: all 0.4s ease;
        transform: scale(1);
        line-height: 1;
        &:nth-child(1) {
          left: -47px;
          position: absolute;
          font-size: 26px;
        }
        &:nth-child(2) {
          right: -47px;
          position: absolute;
          font-size: 26px;
        }
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}

@media screen and (max-width: 790px) {
  .hp_btn {
    a {
      width: 221px !important;
      span {
        width: 220px !important;
      }
      b {
        width: 221px !important;
      }
    }
  }
  .blurmodal_centered {
    h3 {
      font-size: 34px !important;
      max-width: 260px !important;
      margin: 0 auto 12px !important;
    }
    p {

    }
  }
  .store {
    padding-top: 72px !important;
  }
  .store_body {
    .store_body__ttl {
      letter-spacing: 1.2px !important;
      font-size: 30px !important;
      top: -4px !important;
      margin-bottom: 6px !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .hp_btn {
    a, button {
      &::before {
        display: none !important;
      }
      &:hover {
        span {
          border: 1px solid #CDCDCD !important;
        }
      }
    }
  }
  .store_banner__slides {
    height: 524px !important;
  }
  .slide_img {
    text-align: center !important;
  }
  .slide {
    p {
      height: 87px !important;
    }
  }
  .store_banner__nav {
    z-index: 99999;
    div {
      left: unset !important;
      right: unset !important;
    }
  }
}

@media screen and (max-width: 550px) {
  .hp_btn {
    button {
      margin-top: 4px;
    }
    a, button {
      width: 100% !important;
      span {
        width: 100% !important;
      }
      b {
        width: 100% !important;
      }
    }
  }
}


@media screen and (min-width: 960px) {

  .star_card {

    &:hover {
      .sc_star {
        // span {
        //   background-image: url('../images/star_fill.svg');
        // }
        // svg {
        //   path {
        //     fill: #EB3223;
        //   }
        // }
      }
    }
  }
}

@media screen and (max-width: 548px) {
  .star_card {
    margin-bottom: 56px !important;
    .sc_star {
      margin-bottom: 24px !important;
      span {
        width: 29px !important;
        height: 27px !important;
      }
    }
    .sc_text {
      font-size: 18px !important;
    }
    .sc_ikey {
      margin-bottom: 32px !important;
    }
  }
  .star_card:not(.notmob) {
    margin-bottom: 35px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    .sc_star {
      margin-bottom: 0px !important;
      margin-right: 15px;
      svg {
        width: 29px !important;
        height: 27px !important;
        // path {
        //   fill: #EB3223 !important;
        // }
      }
    }
    .sc_text {
      font-size: 18px !important;
      max-width: 82% !important;
      .big {
        font-size: 18px !important;
        line-height: 1 !important;
        margin-top: 0px !important;
        display: unset !important;
        top: 0 !important;
        font-family: 'RobotoCondensed' !important;
        svg {
          width: 10px;
          position: relative;
          top: -2px;
        }
      }
    }
  }
  .sc_text {
    line-height: 1.2 !important;
    br {
      // display: none;
    }
  }
}

@media screen and (max-width: 3800px) {
  .star_card {
    margin-bottom: 92px !important;
  }
}

@media screen and (min-width: 1600px) {
  .star_card {
    margin-bottom: 62px !important;
  }
}

@media screen and (max-width: 768px) {
  .slide_img {
    position: relative !important;
    top: 0 !important;
    left: 0;
    width: 100% !important;
    height: 220px !important;
    padding-top: 4px;
    padding-bottom: 30px !important;
    img {
      max-height: 145px !important;
      &:hover {
        transform: unset !important;
      }
    }
  }
  .features {
    padding-top: 20px !important;
    padding-bottom: 0px !important;
    h2 {
      font-size: 36px !important;
    }
  }
  .store_body__grid, .store_body__flex {
    margin-bottom: 40px !important;
    padding-bottom: 30px !important;
    border-bottom: none !important;
  }
  .storelist {
    padding-top: 20px !important;
    padding-bottom: 0px !important;
    h2 {
      font-size: 36px !important;
    }
  }
  .store_body__flex {
    flex-direction: column !important;
  }
  .slide {
    h1 {
      font-size: 42px !important;
    }
    p {
      font-size: 15px !important;
    }
  }
}

@media screen and (max-width: 960px) {
  .star_card {
    margin-bottom: 86px !important;
  }

  .page_blink {
    width: 1338px !important;
    height: 1338px !important;
    left: -669px !important;
    &.right {
      right: -669px !important;
    }
  }
}

.nopad {
  padding-top: 20px !important;
}