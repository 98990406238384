body {
  margin: 0;
  font-family: 'Bebas Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  //-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  transition: all 0.3s ease;
  &.admined {
    min-height: 100vh;
    background: #101010 url("./images/pattern-grey.svg") 0 0 repeat;
    &::before {
      content: " ";
      position: fixed;
      top: -50%;
      left: -50%;
      width: 200%;
      height: 200%;
      background: linear-gradient(-45deg, #f10000 0%, #000 50%, #0068b7 100%);
      // transition: all 0.3s ease;
      transform: scale(0.5);
      // animation: pulsedd infinite 10s ease;
    }
  }
}

.xmtitle {
  strong {
    color: red;
  }
}

.xbtns {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0 24px;
  button {
    border: none;
    outline: none;
    background-color: red;
    color: #fff;
    padding: 10px 42px;
    border-radius: 10px;
    letter-spacing: 0.8px;
    font-size: 18px;
    line-height: 1.05;
    height: 58px;
    font-weight: 500;
    font-family: "RobotoCondensed";
    &:first-child {
      background-color: transparent;
      color: #000;
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 768px) {
  .bbscx {
    font-size: 38px !important;
  }
}

.ant-table-wrapper .ant-table-tbody>tr>td {
  padding: 8px 16px !important;
}

.picker_xxx {
  padding: 14px 12px !important;
  border: 1px solid #dfdfdf !important;
}

@keyframes pulsedd {
  0% {transform: scale(0.5);}
  50% {transform: scale(1);}
  100% {transform: scale(0.5);}
}

html {
  overflow-x: hidden;
}

#root {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
}

.swiper-button-prev {
  color: #000 !important;
}
.swiper-button-next {
  color: #000 !important;
}

.blue {
  &::before {
    background-color: #2477BD !important;
  }
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon >.ant-steps-icon .ant-steps-icon-dot {
  background: #fff !important;
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  background: #fff !important;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon >.ant-steps-icon .ant-steps-icon-dot {
  background: #fff !important;
}

.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail::after {
  background: rgb(255 255 255 / 30%) !important;
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon >.ant-steps-icon .ant-steps-icon-dot {
  background: rgb(255 255 255 / 25%) !important;
}

.ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-tail::after {
  background: rgb(255 255 255 / 30%) !important;
}

.ant-steps-item-title {
  color: #fff !important;
  font-weight: 800 !important;
  font-size: 28px !important;
}

.ant-popover-inner-content {
  p {
    max-width: 320px !important;
  }
}
.ant-popover {
  z-index: 999999999 !important;
}

.ant-steps-item-description {
  color: #fff !important;
}

.ant-steps.ant-steps-dot .ant-steps-item-content {
  width: 150px !important;
}

table {
  th, td {
    color: #fff !important;
  }
  tr {
    cursor: pointer;
    td {
      color: #fff !important;
      
    }
    &:hover {
      td {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
}

.colled {
  display: block;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-responsive {
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 10px 0px 0px;
  border-radius: 8px;
  background-color: rgba(255,255,255,0.06);
  &.whited {
    border: 1px solid rgba(255, 255, 255, 0.2) !important;
    background-color: rgb(255 255 255) !important;
  }
  .table-bottom {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 12px 16px 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    .table-bottom__item {
      margin-right: 20px;
      display: flex;
      align-items: center;
      &:last-child {
        margin-right: 0px;
      }
      .tbi__ttl {
        font-size: 13px;
        letter-spacing: 1.3px;
        color: rgba(0, 0, 0, 0.5);
        margin-right: 12px;
      }
    }
  }
  .table-actions {
    span {
      margin-right: 16px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
  .table {
    margin-bottom: 0px !important;
    // min-height: 340px;
    &.isloading {
      min-height: unset !important;
    }
    &.light {
      th, td {
        color: rgba(0, 0, 0, 0.5) !important;
        &::before {
          background-color: rgba(0, 0, 0, 0) !important;
        }
        &:hover {
          color: rgba(0, 0, 0, 1) !important;
        }
        .ordered {
          color: rgb(0 0 0 / 24%);
          position: relative;
          top: -3px;
          font-size: 14px;
          left: 2px;
        }
      }
      tr {
        // border-bottom: 1.5px solid #0000000f;
        td {
          color: rgba(0, 0, 0, 0.6) !important;
          &::before {
            background-color: rgba(0, 0, 0, 0.1) !important;
          }
        }
        &:hover {
          td {
            color: #000 !important;
          }
        }
      }
      tbody {
        border-top: 1.5px solid rgba(0, 0, 0, 0.1);
      }
    }
    th, td {
      color: #fff;
      font-weight: 500;
      transition: all 0.3s ease;
    }
    thead {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      tr {
        
        margin-bottom: 8px;
      }
    }
    th {
      border-bottom: none !important;
      padding-left: 16px;
      position: relative;
      font-family: 'RobotoCondensed';
      &:first-child {
        border-left: none !important;
        &:before {
          display: none;
        }
      }
      &:before {
        background-color: rgba(255, 255, 255, 0.3);
        width: 1px;
        height: 21px;
        content: " ";
        position: absolute;
        left: 0px;
      }
    }
    tr {
      cursor: pointer;
      &:last-child {
        td {
          border-bottom: none !important;
        }
      }
      td {
        position: relative;
        color: #fff;
        padding-left: 16px;
        font-family: 'RobotoCondensed';
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
        &:first-child {
          border-left: none !important;
          &:before {
            display: none;
          }
        }
        &:before {
          background-color: rgba(255, 255, 255, 0.3);
          width: 1px;
          height: 21px;
          content: " ";
          position: absolute;
          left: 0px;
        }
      }
      &:hover {
        td {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
}

.ant-pagination .ant-pagination-item a {
  color: #000000a8 !important;
}
.ant-pagination-item-link {
  color: #fff !important;
}

.ant-pagination .ant-pagination-item-active a {
  color: #000 !important;
}

.ant-pagination {
  &.lighted {
    .ant-pagination-item-link {
      color: #000 !important;
      position: relative;
      top: -4px;
      &:disabled {
        color: rgba(0, 0, 0, 0.3) !important;
      }
    }
  }
}

.ant-select-dropdown {
  z-index: 999999999999;
}

@media screen and (min-width: 768px) {
  ::-webkit-scrollbar-button {
    display: none;
  }

  * {
    //scroll-behavior: smooth;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 16px;
  }
  
  ::-webkit-scrollbar-track:hover {}
  
  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }
  
  ::-webkit-scrollbar {
    width: 4px;
    max-height: 4px;
    // background-color: #000;
  }
}

.swiper {
  // overflow: unset !important;
}

.fader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  background-color: #000000;
  transition: all 0.3s ease;
  z-index: 1000000003;
  display: flex;
  justify-content: center;
  div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 240px;
      transition: all 0.3s ease;
      height: initial;
    }
  }
}

@media screen and (max-width: 548px) {
  a {
    &::after {
      display: none !important;
    }
  }
}

@media screen and (max-width: 960px) {
  .fader {
    top: 0px !important;
  }
}

.ant-message {
  z-index: 999999999999;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.centered {
  display: flex;
  align-items: center;
}

.loading {
  height: 100vh;
  height: 100dvh;
}

.swiper {
  padding-bottom: 90px;
}

@media screen and (max-width: 768px) {
  .swiper {
    padding-bottom: 40px;
  }
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 30px !important;
}

.swps {
  padding-bottom: 60px !important;
  .swiper-pagination {
    bottom: 20px !important;
    z-index: 9999999999999 !important;
  }
  .swiper-pagination-bullet {
    cursor: pointer;
    // transition: all 0.6s ease !important;
  }
}

.swiper-pagination-bullet {
  background: #fff !important;
  transition: all 0.1s ease;
}

.swiper-pagination-bullet-active {
  background: #fff !important;
  border: 2px solid #fff !important;
  padding: 5px !important;
}

.modal_text {
  font-size: 14px;
  color: #fff;
  padding-bottom: 4px;
  .smalled {
    font-size: 14px;
    opacity: 0.6;
    line-height: 22px;
    display: block;
    margin-top: 14px;
  }
  .redline {
    background-color: red;
    color: #fff;
    width: fit-content;
    border-radius: 4px;
    padding: 2px 12px;
    margin: 4px 0 20px;
  }
}

.modal_text {
  font-size: 18px;
  font-family: 'RobotoCondensed';
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #000;
}

.modal_text p {
  margin-top: -8px;
  text-align: left;
  margin-bottom: 8px;
}

.modal_action {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 0px;
  width: calc(100% + 32px);
  z-index: 999999999;
  padding: 0px 0px 2px 16px;
  position: sticky;
  bottom: 0px;
  margin-left: -16px;
  background-color: #fff;
  padding: 12px 0;
  // box-shadow: 0px -8px 11px rgb(0 0 0 / 27%);
}

.ant-upload-wrapper .ant-upload-drag .ant-upload {
  padding: 16px 0 0 !important;
}

.Toastify {
  position: relative;
  z-index: 9999999999999999;
  width: 100%;
}

.modal_action div {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.modal_action button {
  color: rgb(0, 0, 0);
  background: transparent !important;
  outline: none !important;
  border: none !important;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 20px;
  margin-right: 16px;
  margin-left: 16px;
}

.ant-select-selection-item {
  color: #000 !important;
}

.modal_action a {
  color: #fff;
  font-weight: 500;
  background: transparent !important;
  outline: none !important;
  border: none !important;
  text-transform: uppercase;
  text-decoration: none;
  margin-left: 12px;
}

@media screen and (max-width: 960px) {
  .modal_actions {
    div {
      flex-direction: column !important;
      button {
        margin-top: 10px;
        margin-right: 0px !important;
        &:first-child {
          margin-top: 0px !important;
        }
      }
    }
  }
}

.modal_actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    button {
      border-radius: 100px;
      background-color: rgba(255, 255, 255, 0.15);
      color: #fff;
      border: none;
      outline: none;
      font-size: 16px;
      padding: 6px 18px;
      margin-right: 12px;
      &:last-child {
        margin-right: 0px !important;
      }
      &.prim {
        background-color: #107be7 !important;
      }
      &.green {
        background-color: rgb(11, 177, 11) !important;
      }
      &.red {
        background-color: rgb(216, 7, 7) !important;
      }
      &.clear {
        background-color: transparent !important;
      }
    }
  }
}

.modal_action button:first-child {
  color: #fff;
  font-weight: 500;
  background: #FF0000 !important;
  outline: none !important;
  border: none !important;
  text-transform: uppercase;
  padding: 8px 24px 6px;
  border-radius: 10px;
  font-size: 20px;
  margin-left: 0px !important;
}

.modal_action button:disabled {
  color: rgb(164, 164, 164);
  cursor: default;
}

.ant-switch {
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid #fff;
  padding-bottom: 5px;
  height: 24px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.2) !important;
  }
  &.ant-switch-checked {
    background-color: #e60909 !important;
    &:hover {
      background-color: #c50000 !important;
    }
  }
}

.modal_forma {
  div {
    label {
      //font-family: 'Futura PT Book';
      font-size: 14px;
      margin-bottom: 6px;

      small {
        color: red;
      }
    }

    input,
    textarea {
      width: 100%;
      border: none;
      color: rgb(0, 0, 0);
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 10px 12px;
      outline: none;
      font-size: 18px;

      &::placeholder {
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }
}

.modal_form {
  div {
    width: 100%;
    margin-bottom: 16px;

    label {
      //font-family: 'Futura PT Book';
      font-size: 14px;
      margin-bottom: 6px;

      small {
        color: red;
      }
    }

    input,
    textarea {
      width: 100%;
      border: none;
      color: rgb(0, 0, 0);
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 10px 12px;
      outline: none;
      font-size: 18px;

      &::placeholder {
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }
}

.awssld__container {
  padding-bottom: 57% !important;
}

.awssld__controls button {
  width: 4% !important;
}

.awssld__controls__arrow-left:before,
.awssld__controls__arrow-left:after,
.awssld__controls__arrow-right:before,
.awssld__controls__arrow-right:after {
  background: #fff !important;
}

.awssld__bullets {
  bottom: 30px !important;
  z-index: 999999999 !important;
}

.awssld__bullets .awssld__bullets--active {
  background: #fff !important;
}

.awssld__bullets button {
  background: #ffffff4a !important;
}

.padded_content {
  padding-top: 120px;
  padding-bottom: 42px;
}

.breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;

  &.light {
    margin-bottom: 32px !important;

    .breadcrumbs_item {
      color: rgba(255, 255, 255, 0.5) !important;

      &:last-child {
        color: rgba(255, 255, 255, 0.8) !important;
      }
    }
  }

  .breadcrumbs_item {
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-left: 4px;

    &:first-child {
      margin-left: 0px !important;
      cursor: pointer !important;
    }

    &:last-child {
      color: rgba(255, 255, 255, 0.8);
    }
  }
}

.page_title {
  font-weight: 700;
  font-size: 48px;
  line-height: 59px;
  color: #ffffff;

  &.light {
    color: #fff;
  }
}

.page_subtitle {
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: #000000;

  &.light {
    color: #fff;
  }
}

.page_desc {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #000000;

  &.light {
    color: #fff;
  }
}

@media screen and (max-width: 960px) {
  .awssld__container {
    padding-bottom: 160% !important;
  }
}

.awssld__controls__arrow-left,
.awssld__controls__arrow-right {
  height: 20px !important;
}

@media screen and (max-width: 767px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: unset !important
  }
}

@media screen and (min-width: 768px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: unset !important
  }
}

@media screen and (max-width: 1280px) {
  .container {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media screen and (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1354px !important;
  }
  .admined {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
      margin: 0 !important;
    }
  }
}

@media screen and (min-width: 1600px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1354px !important;
  }
  .admined {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
      margin: 0 !important;
    }
  }
}

.ant-collapse {
  border: none !important;
  border-radius: 0px !important;

  .ant-collapse-header-text {
    //color: #fff !important;
    font-size: 22px !important;
    font-weight: 500;
  }

  .ant-collapse-expand-icon {
    //color: #fff !important;
    height: 31px !important;

    span {
      font-size: 18px !important;

      svg {}
    }
  }

  .ant-collapse-item {
    border-bottom: 1px solid #00000017;
    &:last-child {
      border-bottom: none !important;
    }
  }

  .ant-collapse-content {
    background-color: transparent !important;
    border-top: none !important;

    //color: #fff !important;
    .ant-collapse-content-box {
      padding-left: 0px !important;
      padding-right: 0px !important;
      padding-top: 0px !important;
      opacity: 0.8;

      p {
        //color: #fff !important;
        font-weight: 400;
        color: #1a1a1a !important;
        text-align: start;
        font-size: 15px;
        letter-spacing: 1px;
      }
    }
  }

  .ant-collapse-header {
    flex-direction: row-reverse !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    align-items: center !important;

    .ant-collapse-header-text {
      font-size: 16px !important;
      color: #000;
      font-weight: 700 !important;
    }
    svg {
      width: 18px !important;
      opacity: 0.3 !important;
    }
  }

  .ant-collapse-item {
    border-radius: 0px !important;
    left: -200px;
    position: relative;
    opacity: 0;
    transition: all 0.3s ease;

    &:last-child {
      border-bottom: none !important;
    }
  }
}

.headSlider {
  width: 100% !important;
  position: absolute;

  img {
    width: 100%;
  }

  .hs_sl {
    height: 630px;
    width: 100%;

    div {
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
      height: 630px;
    }
  }
}

.foodSlider {
  width: 101.3% !important;

  img {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .swiper {
    &.prod {
      height: 100%;
      .swiper-slide {
        text-align: left;
      }
    }
    .swiper-pagination {
      bottom: 0px !important;
      text-align: left;
    }
  }
}

.arhbtn {
  margin-top: 32px;
  background-color: #C8B79D;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 42px;
  border: none;
  outline: none;

  &.arhbtn-primary {
    background-color: #dfa25f;
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
  }

  &.arhbtn-round {
    background-color: #dfa25f;
    color: #fff;
    border-radius: 100px;
    width: 100%;
  }
}

.tiny_pulse {
  display: inline-block;
  animation: tinyPulse 4s infinite alternate;
  transform: scale(1);
}

@keyframes tinyPulse {
  0% {transform: scale(0.98);}
  10% {transform: scale(1.02);}
  30% {transform: scale(1);}
  100% {transform: scale(1);}
}

.ant-upload-wrapper {
  .ant-upload-drag {
    border: none !important;
    background-color: rgb(0 0 0 / 5%);
    color: #000;
    transition: all 0.3s ease;
    .ant-upload-text {
      color: rgb(0, 0, 0) !important;
    }
    .ant-upload-hint {
      color: rgba(0, 0, 0, 0.8) !important;
    }
    .ant-upload-drag-icon {
      span {
        font-size: 42px;
      }
      img {
        max-height: 160px;
      }
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.15);
    }
  }
}

.techworks {
  width: 100lvw;
  height: 100lvh;
  background-color: #000;
  display: flex;
  flex-direction: row;
  align-items: center;
  .techworks_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    div {
      color: #fff;
      font-size: 64px;
      svg {
        color: #fff;
      }
    }
    h1 {
      color: #fff;
      font-size: 42px;
      font-weight: 700;
      letter-spacing: 2px;
    }
    p {
      color: #fff;
      font-size: 20px;
      letter-spacing: 2px;
      max-width: 320px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 548px) {
  .react-grid-item {
    width: 100% !important;
  }
}

body {
  ::selection {
    background: #2477BD;
    color: #fff;
    text-shadow: none;
  }
  &.red {
    ::selection {
      background: #FF0000;
      color: #fff;
      text-shadow: none;
    }
  }
}

.xcheckbox {
  display: block;
	margin: 0 0 10px 0;
	cursor: pointer;
	user-select: none;
	position: relative;

  input[type=checkbox] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
  }

  span {
    display: inline-block;
    position: relative; 
    padding: 2px 0 0 28px; 
    letter-spacing: 1.2px;
    line-height: 1;    
  }

  &.robo {
    span {
      font-family: 'RobotoCondensed';
      padding-top: 1px !important;
      padding-left: 30px !important;
    }
  }

  &.white {
    span {
      line-height: 1.4 !important;
      color: #ffffff91 !important;
      font-weight: 300;
      letter-spacing: 1.6px !important;
      &::before {
        border: 2px solid #fff !important;
      }
      &::after {
        background-color: #fff !important;
      }
    }
  }

  span:before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    position: absolute;
    left: 0;
    top: 1.7px;
    transition: all 0.3s ease;
    background-color: transparent;
    border: 2px solid #000;
    border-radius: 2px;
  }

  span:after {
    content: "";
    display: inline-block;
    width: 9px;
    height: 9px;
    position: absolute;
    left: 3px;
    top: 4.7px;
    opacity: 0;
    transition: all 0.3s ease;
    background-color: #000;
  }

  &.focused {
    input[type=checkbox]:checked + span:before {
      // background-color: #000;
      // border-color: red !important;
    }
    input[type=checkbox]:checked + span:after {
      opacity: 1;
      // background-color: red;
    }
  }

  span:before {
    //box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
  }
   
  /* Hover */
  span:hover:before {
    filter: brightness(110%);
  }
   
  /* Active */
  span:active:before {
    filter: brightness(80%);
  }
   
  /* Disabled */
  input[type=checkbox]:disabled + span {
    color: #666;
    cursor: default;
  }
  input[type=checkbox]:disabled + span:before {
    filter: grayscale(100%);
    opacity: 0.6;
  }
}

#rimg1, #rimg2, #rimg3, #rimg4, #rimg5 {
  transition: all 0.1s linear;
}

.swovun {
  .swiper-container {
    overflow: unset !important;
  }
  overflow: unset !important;
}

@media screen and (max-width: 548px) {
  .swiper-slide {
    //max-width: fit-content;
  }

  // .rooted {
  //   height: 100vh !important;
  //   overflow-y: scroll !important;
  // }
}

@media screen and (max-width: 768px) {
  .myswpp {
    .swiper-slide {
      width: 280px !important;
    }
  }
}

.upload_avatar {
  margin-bottom: 10px !important;
  width: fit-content !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  .ava {
    min-width: 80px;
    width: 80px;
    height: 80px;
    border-radius: 8px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 0px !important;
    margin-right: 16px;
  }
  .upl {
    position: relative;
    top: 37px;
    button {
      border: none;
      background-color: rgb(255, 255, 255);
      padding: 10px 24px 10px;
      font-size: 16px;
      letter-spacing: 0.6px;
      font-weight: 600;
      border-radius: 10px;
      line-height: 1;
      color: #000;
      transition: all 0.3s ease;
      cursor: pointer;
    }
  }
}

.manager_loading {
  text-align: center;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 300px;
  padding-top: 120px;
}

.ant-upload-wrapper .ant-upload-drag .ant-upload-drag-icon img {
  max-width: 96%;
  border-radius: 8px;
}

.ddtxx {
  .tox-toolbar {
    background-color: #060606 !important;
  }
  .tox-tinymce {
    border: 2px solid #242424 !important;
    .tox-editor-header {
      background-color: #060606 !important;
    }
    .tox-toolbar-overlord {
      background-color: #353535 !important;
    }
    .tox-toolbar__primary {
      background-color: #060606 !important;
    }
    .tox-menubar {
      background-color: #060606 !important;
    }
    .tox-mbtn--select {
      color: #fff !important;
      &:hover {
        color: #000 !important;
      }
    }
    .tox-tbtn {
      svg {
        fill: #fff !important;
      }
      &:hover {
        svg {
          fill: rgb(41, 41, 41) !important;
        }
      }
      &.tox-tbtn--enabled {
        svg {
          fill: rgb(41, 41, 41) !important;
        }
      }
      &.tox-tbtn--disabled {
        svg {
          fill: rgb(255 255 255 / 50%) !important;
        }
      }
    }
    .tox-edit-area__iframe {
      background-color: #232323 !important;
    }
  }
  .tox-statusbar {
    background-color: #0e0e0e !important;
    border-top: 1px solid #242424 !important;
    color: rgb(177 177 177 / 70%) !important;
  }
  .tox-statusbar__path-item {
    color: rgb(142 142 142 / 70%) !important;
  }
  .tox-statusbar__branding {
    svg {
      fill:rgb(142 142 142 / 70%) !important;
    }
  }
  .tox-statusbar__resize-handle {
    svg {
      fill:rgb(142 142 142 / 70%) !important;
    }
  }
  .tox-statusbar__wordcount {
    color: rgb(142 142 142 / 70%) !important;
  }
}

#bgAnim {
  #b_act_pag {
    left: -8px !important;
  }
}

.loadingx {
  text-align: center;
  color: #fff;
  font-size: 120px;
  svg {
    
  }
}

.adminx_modal_content {
  padding: 0 60px 0 42px;
  .modalx_form {
    .flexed {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      .modalx_form__item {
        width: 100%;
        margin-right: 16px;
        &:last-child {
          margin-right: 0px !important;
        }
      }
    }
    .modalx_form__item {
      margin-bottom: 20px;
      label {
        color: #000;
        display: block;
        font-family: "RobotoCondensed";
        margin-bottom: 3px;
        small {
          color: red;
        }
      }
      input {
        width: 100%;
        border: none;
        color: rgb(0, 0, 0);
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 10px 12px;
        outline: none;
        font-size: 18px;
        font-family: "RobotoCondensed";
  
        &::placeholder {
          color: rgba(0, 0, 0, 0.7);
        }
      }
    }
  }
}
.adminx_modal_action {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  z-index: 999999999;
  padding: 20px 42px 32px;
  position: absolute;
  bottom: 0px;
  background-color: #fff;
  width: 100%;
  box-shadow: 0 -4px 12px rgba(0,0,0,.1);
  div {
    display: flex;
    align-items: center;
    flex-direction: row;
    button {
      background-color: rgba(0, 0, 0, 0.1);
      color: rgb(0, 0, 0);
      border-radius: 10px;
      padding: 8px 24px 5px;
      outline: none !important;
      border: none !important;
      text-transform: uppercase;
      font-size: 22px;
      letter-spacing: 1.4px;
      font-weight: 500;
      margin-right: 16px;
      &.danger {
        background-color: rgb(198, 13, 13);
        color: rgb(255, 255, 255);
        opacity: 0.6;
        transition: all 0.3s ease;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .adminx_modal_content {
    padding: 0 20px !important;
  }
  .adminx_modal_action {
    padding: 20px 20px 32px;
  }
  .modalx_form {
    .flexed {
      flex-direction: column !important;
    }
  }
}

.color_pick {
  margin-top: 16px !important;
  width: 100% !important;
}

.selectx {
  width: 100%;
  border: none;
  color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px 12px;
  outline: none;
  font-size: 18px;
  font-family: "RobotoCondensed";
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  position: relative;
  select {
    border: none;
    background-color: transparent;
    appearance: none;
    outline: none;
    width: 100%;
    cursor: pointer;
    position: relative;
    z-index: 9999999;
    option {

    }
  }
  .bbb {
    display: block;
    position: absolute;
    right: 16px;
    opacity: 0.6;
    top: 15px;
  }
  span {
    display: block;
    position: absolute;
    right: 16px;
    opacity: 0.6;
  }
}

.switcher-label{
  background-color: var(--label);
  cursor: pointer;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 10px;
  height: auto;
  min-height: 55px; 
  border-radius: 30px;
  position: relative;
}

/*toggler*/
.switcher-label .switcher-toggler {
  background-color: white; 
  width: 32px;
  height: 32px; 
  z-index: 1; 
  border-radius: 50%;
  display: block;
  position: absolute;
  left: 4%;
  top: 20%;
  transition: all 0.4s ease;
}

/*sun and moon images*/
.switcher-label svg {
  font-size: 22px; 
}

.switcher-label svg:first-child {
color: dodgerblue;
}

.switcher-label svg:last-child {
color: gold;
}
.switcher-input{
  opacity: 0;
  display: none;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.switcher-input:checked ~ .switcher-label .switcher-toggler {
  left: 53%;
}

.ant-picker-cell-inner {
  color: #fff !important;
}

.ant-picker-suffix {
  display: none !important;
}

.ant-picker {
  background: unset !important;
  color: unset !important;
  border: unset;
  padding: 0;
  width: 100% !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #383838 !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: #383838 !important;
}

.ant-picker-panel-container {
  background: #202020 !important;
}

.ant-picker-header {
  button {
    color: #fff !important;
  }
}

.chrome-picker {
  z-index: 99999999999999;
  position: relative;
}

.ant-picker-dropdown .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: rgb(255 255 255 / 18%) !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: rgb(255, 255, 255) !important;
  background: #1677ff !important;
  border-color: #1677ff !important;
}

.ant-radio-button-wrapper {
  background: rgba(255, 255, 255, 1) !important;
  color: rgba(0, 0, 0, 0.88) !important;
  border: 1px solid #000000 !important;
  height: 56px !important;
  padding-block: 3px !important;
  padding-top: 11px !important;
  font-size: 15px !important;
  transition: all 0.3s ease;
  span {
    &:nth-child(2) {
      // transition: all 0.3s ease;
    }
  }
  &:not(.ant-radio-button-wrapper-checked) {
    &:hover {
      span {
        &:nth-child(2) {
          color: rgba(0, 0, 0, 0.78) !important;
        }
      }
    }
  }

  &:nth-child(1) {
    &.ant-radio-button-wrapper-checked {
      color: rgb(0, 0, 0) !important;
      background: rgba(0, 0, 0, 0.1) !important;
      border-color: rgba(0, 0, 0, 0.1) !important;
    }
  }
  &:nth-child(3) {
    &.ant-radio-button-wrapper-checked {
      color: rgb(255, 255, 255) !important;
      background: #ff1616 !important;
      border-color: #ff1616 !important;
    }
  }
}

.ant-picker-footer {
  display: none !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
  background-color: #fff !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before  {
  background-color: #fff !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #fff !important;
}

// #animasvg1, #animasvg2 {
//   transition: all 0.4s ease;
//   animation: animasvg 4s ease infinite;
//   transform: translate(-10px, 10px);
// }

// #animasvg2 {
//   animation: animasvg2 4s ease infinite;
// }

@keyframes animasvg2 {
  0% {
    transform: translate(-10px, 10px);
  }
  30% {
    transform: translate(20px, 10px);
  }
  70% {
    transform: translate(20px, 10px);
  }
  100% {
    transform: translate(-10px, 10px);
  }
}

@keyframes animasvg {
  0% {
    transform: translate(-10px, 10px);
  }
  30% {
    transform: translate(-40px, 10px);
  }
  70% {
    transform: translate(-40px, 10px);
  }
  100% {
    transform: translate(-10px, 10px);
  }
}

#rs_grupper {
  font-family: 'RobotoCondensed';
  color: #fff;
  strong {
    color: #e1e1e1;
    font-weight: bold;
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 14px;
    display: inline-block;
  }
  ul {
    padding: 0 0px 10px 0;
    display: block;
    font-size: 15px;
    color: #ffffff;
    line-height: 20px;
    margin: 0px;
    list-style: none !important;
    li {
      list-style: none !important;
      border-bottom: 1px dotted #ccc;
      height: auto;
      margin-bottom: 0px;
      font-size: 15px;
      padding-left: 0 !important;
      content: none !important;
      span {
        color: #e6e6e6;
        background-color: #000;
        bottom: -11px;
        font-weight: normal;
        line-height: 27px;
        font-size: 15px;
        padding-right: 10px;
        position: relative;
      }
      b {
        color: #e6e6e6;
        float: right;
        background-color: #000;
        bottom: -11px;
        font-size: 15px;
        font-weight: normal;
        line-height: 27px;
        padding-left: 10px;
        position: relative;
      }
    }
  }
}

.newpay_title {
  font-family: 'RobotoCondensed';
}

.xplaceholder {
  height: 160vh;
  height: 160svh;
  background-color: #000;
  position: relative;
  z-index: 999999999999999;
}

@media screen and (max-width: 768px) {
  .mtx44 {
    display: inline-block !important;
  }
  .swps {
    padding-bottom: 0px !important;
  }

  .modal_actions {
    padding-bottom: 20px;
    div {
      button {
        background-color: rgba(0,0,0,0.1) !important;
        color: #000 !important;
        font-family: 'RobotoCondensed';
        &.red {
          color: #fff !important;
        }
      }
    }
  }
}

.xbutton {
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0.06);
  color: #000;
  padding: 12px 42px;
  text-align: center;
  font-family: "RobotoCondensed";
  font-size: 18px;
  font-weight: 400;
  border-radius: 8px;
  margin-bottom: 10px;
  transition: all 0.3s ease;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.ant-table-thead {
  .ant-table-cell {
    padding: 10px 16px !important;
    background: none !important;
  }
}

.ant-table-cell {
  color: #000 !important;
  p {
    &:hover {
      color: #000 !important;
    }
  }
}

.ant-table-expanded-row {
  &:hover {
    p {
      color: #000 !important;
    }
  }
}

.ant-table-wrapper {
  .ant-pagination-item-link {
    color: #000 !important;
  }
}

.tabs_table {
  width: 100%;
}

@media (min-width: 768px) and (max-width: 768px) {
  .col-md-6 {
    flex: 0 0 auto !important;
    width: 100% !important;
  }
}

@media (min-width: 769px) {
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}