.lang_switcher {
  display: flex;
  flex-direction: row;
  align-items: center;
  &.dark {
    .langs {
      div {
        color: #000 !important;
        &.divider {
          background-color: #000 !important;
        }
      }
    }
  }
  .langs {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    width: 60px;
    height: 20px;
    position: relative;
    user-select: none;
    div {
      font-family: 'RobotoCondensed';
      color: #fff;
      opacity: 0.6;
      line-height: 1;
      cursor: pointer;
      transition: all 0.2s ease;
      user-select: none;
      margin-right: 6px;
      font-size: 13px;
      &.active {
        opacity: 1;
      }
      &.divider {
        width: 1px;
        height: 10px;
        opacity: 1;
        background-color: #fff;
      }
    }
  }
  .arrow {
    user-select: none;
    img {
      user-select: none;
      width: 6px;
      height: 12px;
      position: relative;
      top: 3px;
      cursor: pointer;
      &:hover {

      }
    }
  }
}

@media screen and (max-width: 768px) {
  .langs {
    div {
      font-size: 16px !important;
      margin-right: 8px !important;
      &.divider {
        height: 13px !important;
      }
    }
  }
}