.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999999999;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.86);
  backdrop-filter: blur(10px);
  animation-name: appear;
  animation-duration: 300ms;
  transition: all 0.3s ease;
  &.centered {
    justify-content: center;
    align-items: center;
  }
}

.modal_dialog {
  width: 100%;
  max-width: 100%;
  // background: #EBEBEB;
  border-radius: 0px;
  position: relative;
  margin: 0 auto;
  padding: 0;
  max-height: 100dvh;
  height: 100dvh;
  text-align: left;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  // box-shadow: 0 4px 38px 0 rgb(0 0 0 / 21%), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  -webkit-animation-name: slide-in;
  -webkit-animation-duration: 0.3s;
  animation-name: slide-in;
  animation-duration: 0.3s;
  transition: all 0.3s ease;
}

@media screen and (max-width: 1399px) {
  .modal_dialog {
    //max-width: 100% !important;
    background-size: contain !important;
  }
}

@media screen and (max-height: 800px) {
  .modal_dialog {
    background-size: contain !important;
  }
}


@media screen and (min-width: 1280px) {
  .modal_dialog {
    max-width: 100% !important;
  }
}

@media screen and (min-width: 1600px) {
  .modal_dialog {
    max-width: 84% !important;
  }
}

@media (min-height: 1082px) and (min-width: 1600px) {
  .modal_dialog {
    max-width: 40% !important;
  }
}

.modal_header__close {
  position: absolute;
  z-index: 99;
  top: 12px;
  right: 12px;
  width: 35px !important;
  height: 35px !important;
  // background: #fff;
  display: block;
  text-align: center;
  padding-top: 5px;
  border-radius: 100px;
  cursor: pointer;
  svg {
    width: 13px;

    path {
      stroke: rgb(255, 255, 255);
    }
  }
}

@media (min-width: 768px) and (max-width: 1280px)  {
  .modal_dialog {
    max-width: 100% !important;
  }
  .modal_header {
    padding: 0 20px !important;
    .modal_header__close {
      right: 0px !important;
    }
    .modal_header__title {
      margin-bottom: 36px !important;
    }
  }
}

@media screen and (max-width: 768px) {

  .modal_body {
    overflow: unset !important;
    overflow-y: unset !important;
    .modal_body__content {
      
      p {
        font-size: 18px;
        line-height: 20px;
      }
    }
  }
  .modal_dialog {
    
  }
  .modal_header__title {
    font-size: 38px !important;
    margin-bottom: 24px !important;
    padding-top: 40px !important;
    br {
      display: block !important;
    }
  }
  .modal_header {
    padding: 0 0 !important;
  }
  .modal_header__close {
    top: 30px !important;
    right: 10px !important;
    svg {
      width: 20px !important;
      height: 20px !important;
    }
  }
}

.modal_header,
.modal_footer {
  display: flex;
  align-items: center;
}

.modal_header {
  justify-content: space-between;
  padding: 0 20px;
  .modal_header__title {
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 1;
    text-align: center;
    color: #ffffff;
    width: 100%;
    text-align: left !important;
    margin-bottom: 55px;
    padding-top: 64px;
    max-width: 310px;
    br {
      display: none;
    }
    span {
      color: #EB3223;
    }
  }
  .modal_header__close {
    cursor: pointer;
    padding: 0px 8px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 24px;
    position: absolute;
    top: 64px;
    right: 12px;
    z-index: 999;
    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.modal_body {
  .modal_body__image {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .modal_body__content {
    iframe {
      width: 100%;
      height: 100vh;
    }
    p {
      font-family: 'RobotoCondensed';
      line-height: 22px;
      font-size: 22px;
      margin-bottom: 10px;
      img {
        width: 100%;
        margin-top: 14px;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .modal_dialog {
    //max-height: calc(100vh - 40px) !important;
  }
}

@media screen and (min-width: 1024px) {
  .modal_body {
    padding: 0 24px;
  }
  .modal_header__title {
    width: 100%;
    //padding-left: 24px;
  }
}

.modal_footer {
  border-top: 1px solid #cacaca;
  justify-content: flex-end;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .modal_header__close {
    position: absolute;
    z-index: 9999999;
    // top: 9px !important;
    // right: 31px !important;
  }
  .modal_body__content {
    padding-bottom: 46px;
    padding-top: 16px;
    // background-color: #fff;
  }
}